// app/core/course.model.ts
import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export class Course {
    // ...

constructor(
	public id: string,
	public productId: string,
	public productName: string,
	public productPrice: string,
  public imageURL: string,
  public clickURL: string,
  public brandedName: string,
  public retailerBrand: string,
  public unbrandedName: string,
  public description: string,
  public retailerName: string,
  public priceLabel: string
	
){}


}

@Injectable({
    providedIn: 'root'
})
export class CourseAdapter implements Adapter<Course> {

  adapt(item: any): Course {
    return new Course(
      item.id,
			item.product.id,
			item.product.name,
			item.product.price,
      item.product.image.sizes.Original.url,
      item.product.clickUrl,
      item.product.brandedName,
      item.product.retailerBrand,
      item.product.unbrandedName,
      item.product.description,
      item.product.retailer.name,
      item.product.priceLabel
    );
  }
}