import { Component, Input, ChangeDetectionStrategy, Output,EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Player, PlayerValue } from "./config/models/player.model";

import { DataService } from './data.service';

@Component({
  selector: "selected-player",
  changeDetection: ChangeDetectionStrategy.OnPush,
  // template: `<h1>Hello {{ player?.name }}!</h1>`
  templateUrl: './selected-player.html',
})
export class SelectedPlayerComponent {
  selectedMessage:any;

  constructor(
  private data: DataService 
) {}

  // value = 10;
  // VALUE IMPORT
  private _selectedValue: PlayerValue;

  @Input()
  set playerValue(value: PlayerValue) {
    console.log(value);
    this._selectedValue = value;
  }

  get playerValue(): PlayerValue {
    // console.log(this._selected);
    return this._selectedValue;
  }
  // END VALUE IMPORT

  

  // PLAYERS
  private _selected: Player;

  @Input()
  set player(value: Player) {
    console.log(value);
    console.log('hello?');
    this.data.changeMessageAny(value);

    
    this._selected = value;
  }

  get player(): Player {
    // console.log(this._selected);
    return this._selected;
  }
  // END PLAYERS


  @Output()
  change: EventEmitter<BehaviorSubject<number>> = new EventEmitter<BehaviorSubject<number>>();
  
//   {
//     "id": 4,
//     "name": "Bowser"
// }
// this.selectedId$ = new BehaviorSubject(3)
  increment(id: number){
    console.log(this._selected);
    console.log(id);
    const sendto = new BehaviorSubject(id);
    this.change.emit(sendto)
  }

  getCurrentValue(){
    console.log(this.playerValue);
    // var currentValue: Fruit = this.data.getCurrentMessage();
    // console.log(currentValue);
  }

  ngOnInit() {
    // this.data.currentMessageAny.subscribe(message => (this.selectedMessage= message)); //<= Always get current value!

    

    var currentAny = this.data.currentAny.subscribe(message => {
      if(message) {
              // console.log('cccccccccccccc data below');
      this.selectedMessage = message;
      console.log(message);

      console.log(this.selectedMessage);
      // console.log(this.data.currentBrand);
      // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkk data UP UP');

        // then extract the data from response over here
          // assign the extracted 'data' array to a component variable named data.
    // this.brandObject = databrands;

      // this.newBrandGet(databrands);


      } else {

        console.log('nooooooooooooooo? ngonit? ')

      }







      });
// BRAND END
   }


   areYouThere() {
    // this.data.currentMessageAny.subscribe(message => (this.selectedMessage= message)); //<= Always get current value!

    // this.data.changeMessageAny("wow wow wow wow child updated here");
    // this.data.changeAnyValue("YOU CHANGED!?!?!");

    this.data.changeMessageAny("CHILD - areYouThere fired, the message has been set by CHILD");
    


    let smells = this.data.getCurrentMessageAny();
    console.log(smells);

// getCurrentMessageAny
    // var currentAny = this.data.currentMessageAny.subscribe(message => {
    //   if(currentAny) this.selectedMessage = this.selectedMessage;

      
    //   this.selectedMessage = message;
      
      
    //   console.log(this.selectedMessage);
      
      

    //   });
// BRAND END
   }

// EVERYTHING GOES ABOVE THIS (THIS IS END OF EXPORT CLASS)
}