import {  OnInit,NgModule, Component,Input,Output,EventEmitter } from '@angular/core';
import { speedDialFabAnimations } from './speed-dial-fab.animations';
import { UserProvider, AsyncPipeComponent } from '../../app/reddit-data.service';
import { DataService } from '../../app/data.service';

// mat paginator
import {PageEvent} from '@angular/material/paginator';
// end mat paginator


@Component({

  selector: 'app-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations
})



export class SpeedDialFabComponent implements OnInit {

// mat paginator
@Input() componentTitle: string;
  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

    // MatPaginator Output
    pageEvent: PageEvent;

// end mat paginator


 //readmore variable, its true than read more string will print
 ReadMore:boolean = true

 //hiding info box
 visible:boolean = false

count : number = 0;
// childMessage:any;
listOfFoos: any;
listOfFoosLength: any;
listOfFoosOriginal: any;
dataPermiso:any;
UserProvider: any;
fingers:any;
message: string = "Hola Mundo!";
cartObject:object;
@Output() sidenav: EventEmitter<any> = new EventEmitter();
@Output() messageEvent = new EventEmitter<string>();
@Output() searchbar: EventEmitter<any> = new EventEmitter();

@Output() bottomSheet: EventEmitter<any> = new EventEmitter();


// 1_CALLS API MUST LOOK INTO
//  namez : string = "72";
//took out 72 and made it any
namez:any;

  isOpen: boolean;

// I added this because childmessage by itself as :any was not working.
  @Output() childMessage: EventEmitter<any> = new EventEmitter<{ clicks: number }>();


  @Output() open: EventEmitter<boolean> = new EventEmitter()

@Output() counterChange :  EventEmitter<number>;
  constructor(public service: UserProvider, public prom: AsyncPipeComponent, private data: DataService) {
this.counterChange = new EventEmitter();

// removing these two to avoid it from running the api calls
// this.loadPermiso(this.namez);
// console.log(this.namez);


  }

  ngOnInit() {
    // this.data.currentMessage.subscribe(message => this.message = message);

    // I REMOVED BELOW 10-17-2022 - seems to do nothing.  data is still loaded.
    //   this.data.currentMessage.subscribe(cartObject => {
    //     if(cartObject) this.cartObject = cartObject; 
    //  });
    // console.log(this.cartObject);

  //  this.data.changeMessage({
    
  // });


// this.fingers = 'o-o';
// console.log(this.fingers);

  }


  // mat paginator 
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  // end mat paginator


    //onclick toggling both
    onclick()
    {
      this.ReadMore = !this.ReadMore; //not equal to condition
      this.visible = !this.visible
    }

  sendMessage() {
    this.messageEvent.emit(this.message)
  }

  newMessage() {
    this.data.changeMessage("37")

  }

  GoodGoodMessage() {
    console.log(this.data.currentMessage);
  //  this.data.changeMessage(this.loadPermiso('38'));
  this.data.changeMessage((Math.floor(Math.random() * 60) + 1).toString());
console.log('GOOD GOOD IN FAB COMPONENT');
  let response = this.data.currentMessage.subscribe(data => {
console.log(data);
// this.loadPermiso(data);

});




  }


  GodMessage(god) {
    console.log(god);
    console.log(this.data.currentMessage);
  //  this.data.changeMessage(this.loadPermiso('38'));
  // this.data.changeMessage(god.toString());

// switch (god) {
//   case god.hasOwnProperty('iexist'):
//     console.log('I EXIST');
//     break;
//   case god.hasOwnProperty('iexistSearch'):
//     console.log('I EXIST SEARCH');
//     break;
//   default:
//     console.log('GUESS NOT thanks!');
// }

  var myProp = 'iexist';
if(god.hasOwnProperty(myProp)){
    console.log("yes, i have that property");
    console.log(god);

    switch (god.iexist) {
      case 'menu':
        console.log('I MENU EXIST');
        this.toggle();
        break;
      case 'search':
        console.log('I SEARCH EXIST SEARCH');
        this.toggleSearch();
        break;
        // toggleBottomSheet
        case 'share':
        console.log('I SEARCH EXIST SEARCH');
        this.toggleBottomSheet();
        break;
      default:
        console.log('I DO NOT EXISTGUESS NOT thanks!');
    }

} else {
  console.log("carry on");


  // REMOVING THIS IN FAVOR OF SPREAD OPERATOR ChangeValue
  // this.data.changeMessage(god);
  this.data.changeValue(god);



console.log('GOD MODE FAB COMPONENT');
// I DONT THINK THIS LET RESPONSE DOES ANYTHING. REMOVE?
  let response = this.data.currentMessage.subscribe(data => {
  console.log(data);
  // this.loadPermiso(data);

  });

}


}


navOpen($event): void {
  // toggle condition here
  this.isOpen = !this.isOpen;
  console.log('$navOpen');
}


  clickMenu() {
    this.open.emit(true);
     console.log("click menuZZZZZZZZZZZZZZZZZZZZZZZZZZZZ");


     //console.log('header ', this.sidenav)
      this.loadToggle();
  }


@Input()
   get counter(){
       return this.count;
       console.log('AAAAAAAAAAAAAA');
   }
   increment(){
       this.count = this.count+1;
       console.log('BBBBBBBBBB');
       this.counterChange.emit(this.count);
   }
   decrement(){
       this.count = this.count - 1;
       console.log('CCCCCCCCCCCCC');
       this.counterChange.emit(this.count);
   }


  fabButtons = [
    {
      icon: 'menu',
      routerLink: 'page2',
      fun: {
        iexist: 'menu'
      },
    },
    {
      icon: 'casino',
      routerLink: 'page2',
      fun: {
        ids: 14,
        limit: 50,
        fl1: 0,
        retailerID: Math.floor(Math.random() * 60) + 1
      },
    },
    {
      icon: 'share',
      routerLink: 'page2',
      fun: {
        iexist: 'share'
        // ids: 17,
        // limit: 33,
        // fl1: 0,
        // retailerID: 38,
        // searchText: ""
      },
    },
    {
      icon: 'favorite',
      routerLink: 'page2',
      fun: {
        ids: 11,
        limit: 50,
        fl1: 0,
        // retailerID: 2,
        brandID: 3399,
        searchText: "wire"
        // cat: "thongs"
      },
    },
    {
      icon: 'search',
      routerLink: 'page2',
      fun: {
        iexist: "search"
      },
    }
  ];
  buttons = [];
  fabTogglerState = 'inactive';



  // constructor() { }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  reincrement(numnum: string){
      this.count = this.count+1;
      console.log('STRINGEY ADD');
  }

  updateParent = (): void => { 
    // i took this out because it had an error that was undefined
    // and i don't know how to get it to work. look at it later!
    this.childMessage.emit('Hello! My Parent !!!');
      
   
   }

  toggle() {
    this.sidenav.emit();
  }

  toggleSearch() {
    this.searchbar.emit();
  }
 
  toggleBottomSheet() {
    this.bottomSheet.emit();
  }

   public loadPermiso(ids: number,
    // searchText: string,
    limit: number,
    fl1: number,
    retailer: number){
   //this.service.gett('45').subscribe(data => {
    this.service.gett({
      // ids: 21,
      limit: 12,
      fl1: 0,
      retailerID: 28
    }).subscribe(data => {
       console.log('AAAAAAAAAAAAA RESPONSE');
       console.log(data);
       console.log('AAAAAAAAAAAA RESPONSE');
       //  this.listOfFoos = data;
       //   this.listOfFoosOriginal = data;
       // this.listOfFoosLength = data.length;
       this.listOfFoos = data;
        this.listOfFoosOriginal = data;
      this.listOfFoosLength = data.length;

       this.dataPermiso= data;
       return this.dataPermiso;
       //console.log(this.listOfFoos);
       //return this.listOfFoos;
       });


     }



        public loadToggle(){
        //this.service.gett('45').subscribe(data => {

console.log('WOOOT WOOT');

            //console.log(this.listOfFoos);
            //return this.listOfFoos;



          }

          public callext(){
            //this.service.gett('45').subscribe(data => {
    
    console.log('EXT WOOT WOOTWOOT');
    
                //console.log(this.listOfFoos);
                //return this.listOfFoos;
    
    
    
              }

}
