import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input,
  ComponentRef,
  Compiler,
  OnDestroy,
  ComponentFactoryResolver
} from '@angular/core';
import { SidebarModule } from '../sidebar.module';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.css']
})
export class WidgetContainerComponent implements OnInit, OnDestroy {
  @ViewChild('content', { read: ViewContainerRef })
  content: ViewContainerRef;

  @Input() selector: string;
  @Input() settings: any;

  private componentRef: ComponentRef<any>;

  constructor(
    private sidebarService: SidebarService,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const type = this.sidebarService.widgets[this.selector];
    if (type) {
      const factory = this.cfr.resolveComponentFactory(type);

      this.viewContainerRef.clear();
      this.componentRef = this.viewContainerRef.createComponent(this.sidebarService.widgets[this.selector]);


    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
