import { HttpClient, HttpParams} from '@angular/common/http';
// import { Http, Response, Headers } from '@angular/http';
// ALLEY 3
import { HttpResponse, HttpHeaders } from '@angular/common/http';

import { map, delay, mergeMap, from, shareReplay} from 'rxjs';

// import { mergeMap } from 'rxjs/operators';


import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
import { Observable } from 'rxjs';
// import { catchError, mergeMap, delay, mergeAll } from 'rxjs/operators';
import { pipe, throwError, of, forkJoin } from 'rxjs';
import { tap } from "rxjs/operators";
import { FlatTreeControl } from '@angular/cdk/tree';

import { Course, CourseAdapter } from './course.model';

import { CourseCats, CourseCatsAdapter } from './coursecats.model';

import { CourseBrands, CourseBrandsAdapter } from './coursebrands.model'

import { CourseRetailers, CourseRetailersAdapter } from './courseretailers.model'

// import {map} from 'rxjs/operators';
// import { Observable } from 'rxjs';
//import { Observable } from 'rxjs';

// import 'rxjs/add/operator/mergeMap';

// import 'rxjs/add/observable/from';
import { DataService } from './data.service';

// class Course {
// 	constructor(
// 		public ip: string
// 	){}
	
	
// }

import { Constants } from './config/constants'; 

import { CustomURLEncoder } from './urlencoder.component';
import { Options } from 'selenium-webdriver';

const getData = (parm) => {
	return of('retrieved new data with parm ${parm}').pipe(
		delay(1000)
	)
}

function ensureArray(object) {
	if (typeof object === 'undefined') {
	  return [];
	}
  
	if (Array.isArray(object)) {
	  return object;
	}
  
	return [object];
  }

function* flatten(node, parent) {
	yield { id: node.id, parentId: parent?.parentId };
  
	for (const child of ensureArray(node.children))
	  yield* flatten(child, node);
  }


const GetSumObserver = {
  sum: 0,
  next(value) {
    this.sum = this.sum + value;
  },
  error() { },        
  complete() {
    console.log('Total Sum: ' + this.sum);
  }
};
  



@Injectable({
	providedIn: 'root'
})
export class UserProvider {

// data: any;

posts: any;
		
		films: Observable<any>;
		safeCity:any;


public userData: any = [];
public userDataCats: any = [];
public userDataBrands: any = [];
public userDataRetailers: any = [];

anydata: any;

favourites: any;

id: any;
//myUser = [];

    moo: any = [];

	message:string;
	cartObject:object;
	catObject:object;

	
// original, commented out to test another url
// private baseUrl = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21';
private baseUrl = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21&limit=12';



    constructor(private http: HttpClient, private adapter: CourseAdapter, 	private data: DataService, private adaptercats: CourseCatsAdapter, private adapterbrands: CourseBrandsAdapter, private adapterretailers: CourseRetailersAdapter) {
	


//        this.http.get('https://www.reddit.com/r/gifs/top/.json?limit=10&sort=hot').pipe(map(res => res.json())).subscribe(data => {
	//				console.log(data);
					
					
	//			} );



// console.log('hello from service');
// console.log('returning this:');
// return this.http.get('http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21').subscribe(data => {
// 		console.log(data);
// 		});


// console.log('hello from service');
// console.log('returning this:');
// return this.http
// 		.get('http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21')
// 		.subscribe(data => {
// 	//	console.log(data);
// 		this.posts = data.favorites;
// 		});

///
//return this.http.get('https://www.reddit.com/r/gifs/new/.json?limit=10');
 //return this.http.get('http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21');
     
///

// return this.http
// 		.get('http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21'); 
	
	

// console.log('hello from Reddit-Data service');

// this.films = this.http.get('https://ipinfo.io/8.8.8.8/geo')
// .subscribe(data => {
// 	console.log(data.ip)
// });

//.pipe(map(res => this.data ||[]))

//console.log(this.films);

// console.log('returning this:');
// this.films = this.http
// 		.get('http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21')
// // 		.subscribe(data => {
		
// // 		console.log(data);
// // 	  this.posts = data.favorites_;
// // 		console.log(this.posts);
// // 		},
		
// // 		)
// 		;
	
//	console.log(this.films);



// 	console.log('going to diees');
	
		


//Observable.of(10, 20, 30).subscribe(GetSumObserver);
 
// "Sum equals: 60"



	

}

public get(url: string, options?: any) { 
	return this.http.get(url, options); 
	} 

	

   gett(
	options?: any
	// ids: number,	
	// limit: number,
	// fl1: number,
	// retailer: number,

	// searchText: string,
	// page: number,
	// userId: string,
	// include: string
	): Observable<Course[]> {
	 	

	// console.log('IDS below');
	// console.log(ids);
	console.log(options);
	// console.log('IDS UP UP');	
	// let encodedName = new CustomURLEncoder();
	// encodedName.encodeValue("red dress");
    // console.log(encodedName.encodeValue("red dress"));
// console.log(ids);


//this.films = this.http.get('https://ipinfo.io/8.8.8.8/geo')
// .subscribe(data => {
// 	console.log(data.ip)
// });
//console.log('bomboyage');


 //	const url = '${this.baseUrl}/';
     	// const url = 'http://fooo/' + ids;
	
	
	// original string
//  const url2 = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r'+ ids;
// const url2 = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fts=fraser&fl=b3399&limit=50';
// const url2 = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fts=wire&fl=b3399&limit=50';
// const url2 = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18';
// const url2 = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fts=wire&fl=b3399&limit=50';

// 	return Observable.from(this.http.get('https:ipinfo.io/' + id + '/geo')
// console.log(Constants.TitleOfSite); 
// console.log((new Constants).API_ENDPOINT);
// console.log(url);



var a: number;
var b: number = null;

// check(a, 'a');
// check(b, 'b');
check(options.retailerID, options.retailerID);
// foo(options.retailerID);


//&limit=50
//&fl=d0
//&fl=r'+ ids;
let params = new HttpParams({encoder: new CustomURLEncoder() })

// console.log(options.retailerID);



// console.log(options.retailerID);
// params.delete(options.retailerID);
// console.log(options.retailerID);


// params = params.has("limit") ? params.append("limit",options.limit) : params.set("limit",options.limit);
// params = params.has("fl1") ? params.append("fl",'d'+options.fl1) : params.append("fl",'d'+options.fl1);
// params = params.has("searchText") ? params.append("fts",options.searchText) : params.set("fts",options.searchText);

//  params = params.has("person") ? params.append("person",key) : params.set("person",key);
// if (!params.has('retailerID')) {
//     params = params.set('retailerID', 33)
// }
// Object.keys(options).forEach((value:any, key:any) => {
// 	// params = params.set(k, options[k]);
// 	console.log(key);
// 	console.log(value);
// 	params = params.has("retailerID") ? params.append("fl",'r'+options.retailerID) : params.set("fl",'r'+options.retailerID);

//   });

  
// params = params.appendAll({ [options.searchText.id] : options.searchText.value});
// params = params.appendAll({ 'ids': options.searchText });


//   console.log(options.searchText);
//   console.log(params);


// .set('q', searchText)
// .set('page', page.toString())

// REMOVED FOR NOW
// .set('limit', limit.toString())
// .set('fl', 'd'+fl1.toString())
// .set('fl', 'r'+retailer.toString());

// .set('include', include);


//this.data.changeMessage("REPORTED!!!");
   
    console.log('REDDIT-SERVICE DATA.CURRENTMESSAGE:');
    console.log(this.data.currentMessage);
	// this.data.currentMessage.subscribe(message => this.message = message);

	// I REMOVED BELOW 10-17-2022 - seems to do nothing.  data is still loaded.
	// this.data.currentMessage.subscribe(cartObject => {
    //     if(cartObject) this.cartObject = cartObject; 
	// 	console.log(this.data);
    //  });
	// console.log(this.cartObject);
	 
    //  this.data.changeMessage({
      
    // });

	// I REMOVED BELOW 10-17-2022 - seems to do nothing.  data is still loaded.
	// let jazz = setParams(this.cartObject);

	// REPLACEMENT jazz below
	let jazz = setParams(options);

	let jazzy = jazz.toString();
	console.log(jazzy);



// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=utf-8',
// 	'Access-Control-Allow-Origin': 'https://alleysix.com',
// 	'Access-Control-Allow-Methods': 'GET, OPTIONS',
// 	'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
//   })
// };

const httpOptions = {
	//   'Content-Type':  'application/x-www-form-urlencoded;charset=utf-8',
	//   'Access-Control-Allow-Origin': '*',
	//   'Access-Control-Allow-Origin': 'https://alleysix.com',
	//   'Access-Control-Allow-Methods': 'GET, OPTIONS',
	//   'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with,Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
	  'Content-Type': 'application/json'
	  // Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token
  };




	// let paramsMap = new Map<any,any>();
	// paramsMap.set('limit', limit.toString());
	// paramsMap.set('fl', 'd'+fl1.toString());
	// paramsMap.set('fl', 'r'+retailer.toString()); 
	// console.log(paramsMap);  

	// let paramso = new HttpParams();
	// paramsMap.forEach((value: any, key: any) => {
	// 	paramso = paramso.set(key, value);
	// });
	// console.log(paramso);

	// console.log(this.message);
	console.log(jazz);
//  this.data.changeMessage(url);

let goat = this.http.get<Course[]>(
	// (new Constants).API_ENDPOINT_HTTPS+
	(new Constants).API_ENDPOINT+
	// (new Constants).API_ENDPOINT_PATH_INIT+
	// (new Constants).API_ENDPOINT_PATH_LIST+
	(new Constants).API_ENDPOINT_PATH_SEARCH,
	// (new Constants).API_KEY,
	{params: jazz
		, headers: new HttpHeaders(httpOptions)
	});

// console.log(goat);
// let moat = removeParamFromURL(goat,'al');
// console.log(moat);



	return (this.http.get<Course[]>(
		// (new Constants).API_ENDPOINT_HTTPS+
		(new Constants).API_ENDPOINT+
		// (new Constants).API_ENDPOINT_PATH_INIT+
		// (new Constants).API_ENDPOINT_PATH_LIST+
		(new Constants).API_ENDPOINT_PATH_SEARCH+jazzy,
		// (new Constants).API_KEY,
		{    headers: new HttpHeaders(httpOptions)
		}
		)
	.pipe(map((data: any[]) => {

		
			
		this.userData = data;
		

		if(this.userData.length > 0) {
			console.log('favorites below');
			console.log(this.userData.favorites);
			console.log('fav ups');	
		  }


		 




// 			return data.favorites.map(item => this.adapter.adapt(item));
		return this.userData.favorites.map(item => this.adapter.adapt(item));
		
		//return data;
		
		}
		)
		).pipe(
		shareReplay(1)
			));
	 
 

	

	//.subscribe(result => console.log(ip));
	//);
	
// 	.subscribe(val => console.log(val)));
	
	
	
	
	
	
// let getContentForLocale: Observable<any> = getLocale => {
// return this.http.get("http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fl=b3510&fl=b689&fl=r21");
// 	 }
	 
	 
// let handleContent = content => {
//   //do something with the contentsssss
// console.log('hello in here');
// }

// return getLocale
// .pipe(
// 	mergeMap((getContentForLocale) => newObservable(getContentForLocale))
// 	).subscribe(handleContent);

// console.log('made it out?');





		}				
			
	gettcat(
		options?: any

		): Observable<CourseCats[]> {
			

		// console.log('IDS below');
		// console.log(ids);
		console.log(options);
		// console.log('IDS UP UP');	




	var a: number;
	var b: number = null;


	check(options.cat, options.cat);

	let params = new HttpParams({encoder: new CustomURLEncoder() })



		console.log('REDDIT-SERVICE CAT DATA.CURRENTMESSAGE:');
		console.log(this.data.currentCat);

		let jazz = setParams(options);

		// http://api.shopstyle.com/api/v2/categories?pid=uid5321-992139-18
		// https://5sm2yd4nni.execute-api.us-east-1.amazonaws.com/prod/category/{categoryId}
		return (this.http.get<CourseCats[]>(
			(new Constants).API_ENDPOINT+
			// (new Constants).API_ENDPOINT_APIGW_STAGE+
			(new Constants).API_ENDPOINT_PATH_CATEGORY
			// options.cat
			// (new Constants).API_ENDPOINT_PATH_INIT+
			// (new Constants).API_ENDPOINT_PATH_CATEGORY+
			// (new Constants).API_KEY,
			// {headers: new HttpHeaders()
			// 	.set('Access-Control-Allow-Origin','*')
				// .set('Access-Control-Allow-Methods','GET, OPTIONS')
				// .set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept')
				// .set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
			// }
			)
		.pipe(map((data: any[]) => {


			this.userDataCats = data;
			
			
	
			const nodes = flatten(this.userDataCats, this.userDataCats.categories[0]);
			console.log(nodes);
			// Iterate over Generator object
for (const value of nodes) {
	console.log(value)
  }

			if(this.userDataCats.length > 0) {
				console.log('cats below');
				console.log(this.userDataCats.id);
				console.log('cats ups');	
			} else {
				console.log('NO LENGTH FOUND');
			}



	// 			return data.favorites.map(item => this.adapter.adapt(item));
			return this.userDataCats.categories.map(item => this.adaptercats.adapt(item));
			
			//return data;
			
			}
			)
			).pipe(
			shareReplay(1)
				));
		
			}				
	
	gettbrand(
		options?: any

		): Observable<CourseBrands[]> {
			

		// console.log('IDS below');
		// console.log(ids);
		console.log(options);
		// console.log('IDS UP UP');	




	var a: number;
	var b: number = null;


	check(options.brand, options.brand);

	let params = new HttpParams({encoder: new CustomURLEncoder() })



		console.log('REDDIT-SERVICE BRANDS DATA.CURRENTMESSAGE:');
		console.log(this.data.currentBrand);

		let jazz = setParams(options);

		// http://api.shopstyle.com/api/v2/categories?pid=uid5321-992139-18
		return (this.http.get<CourseBrands[]>(
			// (new Constants).API_ENDPOINT_HTTPS+
			(new Constants).API_ENDPOINT+
			// (new Constants).API_ENDPOINT_PATH_INIT+
			(new Constants).API_ENDPOINT_PATH_BRAND
			// (new Constants).API_KEY,
			// {params: jazz, headers: new HttpHeaders()
			// 	.set('Access-Control-Allow-Origin','https://alleysix.com')
			// 	.set('Access-Control-Allow-Methods','GET, OPTIONS')
			// 	.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept')
			// 	.set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
			// }
			)
		.pipe(map((data: any[]) => {


			this.userDataBrands = data;
			
			
	
			const nodes = flatten(this.userDataBrands, this.userDataBrands.brands[0]);
			console.log(nodes);
			// Iterate over Generator object
for (const value of nodes) {
	console.log(value)
	}

			if(this.userDataBrands.length > 0) {
				console.log('brands below');
				console.log(this.userDataBrands.id);
				console.log('brands ups');	
			} else {
				console.log('NO LENGTH FOUND');
			}



	// 			return data.favorites.map(item => this.adapter.adapt(item));
			return this.userDataBrands.brands.map(item => this.adapterbrands.adapt(item));
			
			//return data;
			
			}
			)
			).pipe(
			shareReplay(1)
				));
		
			}				

// retailers
	gettretailer(
		options?: any

		): Observable<CourseRetailers[]> {
			

		// console.log('IDS below');
		// console.log(ids);
		console.log(options);
		// console.log('IDS UP UP');	




	var a: number;
	var b: number = null;


	// was options.brand on both
	check(options.name, options.name);

	let params = new HttpParams({encoder: new CustomURLEncoder() })



		console.log('REDDIT-SERVICE RETAILER DATA.CURRENTMESSAGE:');
		console.log(this.data.currentRetailer);

		let jazz = setParams(options);

		// http://api.shopstyle.com/api/v2/categories?pid=uid5321-992139-18
		return (this.http.get<CourseRetailers[]>(
			// (new Constants).API_ENDPOINT_HTTPS+
			(new Constants).API_ENDPOINT+
			// (new Constants).API_ENDPOINT_PATH_INIT+
			(new Constants).API_ENDPOINT_PATH_RETAILER
			// (new Constants).API_KEY,
			// {params: jazz, headers: new HttpHeaders()
			// 	.set('Access-Control-Allow-Origin','*')
			// 	.set('Access-Control-Allow-Methods','GET, OPTIONS')
			// 	.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept')
			// 	.set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
			// }
			)
		.pipe(map((data: any[]) => {


			this.userDataRetailers = data;
			
			
	
			const nodes = flatten(this.userDataRetailers, this.userDataRetailers.retailers[0]);
			console.log(nodes);
			// Iterate over Generator object
for (const value of nodes) {
	console.log(value)
	}

			if(this.userDataRetailers.length > 0) {
				console.log('Retailers below');
				console.log(this.userDataRetailers.id);
				console.log('Retailers ups');	
			} else {
				console.log('NO LENGTH FOUND');
			}



	// 			return data.favorites.map(item => this.adapter.adapt(item));
			return this.userDataRetailers.retailers.map(item => this.adapterretailers.adapt(item));
			
			//return data;
			
			}
			)
			).pipe(
			shareReplay(1)
				));
		
			}				
// end retailers			
					 

// paste above this line or die!
}

function check(x, name) {
    if (x == null) {
        console.log(name + ' == null');
    }

    if (x === null) {
        console.log(name + ' === null');
    }

    if (typeof x === 'undefined') {
        console.log(name + ' is undefined');
		
    }
}

function foo(x: number, y: string = "default", z?: number) {
	// ...
	console.log('it is so');
  }


//   function removeParamFromURL(url, param) {
// 	const urlObj = new URL(url);
  
// 	urlObj.searchParams.delete(param);
// 	return urlObj.href;
//   }
  

  

function removeNullValuesFromQueryParams(params: HttpParams) {
	const paramsKeysAux = params.keys();
	paramsKeysAux.forEach((key) => {
	  const value = params.get(key);
	  if (value === null || value === undefined || value === '') {
		params['map'].delete(key);
	  }
	});
  
	return params;
  }
  
  function setParams(options:any) {
	let params = new HttpParams({ encoder: new CustomURLEncoder() } );
	if(!options) return params;
  
	if(options.key && options.value){
	  return params.set(options.key, options.value);
	}

		// params = params.set("cat","womens-intimates");
console.log(options);
		
	params = options.hasOwnProperty('fl1') ? params.append("fl",'d'+options.fl1) : params.delete("fl",'d'+options.fl1);
	params = options.hasOwnProperty('retailerID') ? params.append("fl",'r'+options.retailerID) : params.delete("fl",'r'+options.retailerID);
	params = options.hasOwnProperty('limit') ? params.append("limit",options.limit) : params.delete("limit",options.limit);	
	params = options.hasOwnProperty('searchText') ? params.set("fts",options.searchText) : params.delete("fts",options.searchText);

	console.log('params222222222');
	console.log(params);
	

	params = options.hasOwnProperty('cat') ? params.set("cat",options.cat) : params.delete("cat",options.cat);
	params = options.hasOwnProperty('brandID') ? params.set("fl",'b'+options.brandID) : params.delete("fl",'b'+options.brandID);
	params = removeNullValuesFromQueryParams(params);
	
  
console.log('params');
console.log(params);


	return params;
  }
  
  function removeParamFromURL(url, param) {
	const [path, searchParams] = url.split('?');
  
	const newSearchParams = searchParams
	  ?.split('&')
	  .filter((p) => !(p === param || p.startsWith(`${param}=`)))
	  .join('&');
  
	return newSearchParams ? `${newSearchParams}` : path;
  }
  


export class AsyncPipeComponent {
//   promiseData: string;
	
public promiseData: {};
v: any;

  constructor() {
		this.getPromise().then(v => this.promiseData = v);
  }
	
	
getPromise() {
     return new Promise((resolve, reject) => {
       setTimeout(() => resolve("Promise complete!"), 3000);
     });
  }
}