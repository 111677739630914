// app/core/course.model.ts
import { Injectable } from '@angular/core';
// import { Adapter } from './adapter';
import { AdapterCats } from './adaptercats';

export class CourseCats {
    // ...

constructor(
	public id: string,
	public name: string,
	public shortName: string,
	public fullName: string,
  public parentId: string,
  public hasHeelHeightFilter: boolean,
  public hasSizeFilter: boolean,
  public hasColorFilter: boolean,
  public showMaterialFilter: boolean,
  public originalId: string
){}


}

@Injectable({
    providedIn: 'root'
})
export class CourseCatsAdapter implements AdapterCats<CourseCats> {

  adapt(item: any): CourseCats {
    return new CourseCats(
      item.id,
			item.name,
			item.shortName,
      item.fullName,
      item.parentId,
      item.hasHeelHeightFilter,
      item.hasSizeFilter,
      item.hasColorFilter,
      item.showMaterialFilter,
      item.id
    );
  }
}