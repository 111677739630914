import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-widget-3',
  templateUrl: './sidebar-widget-3.component.html',
  styleUrls: ['./sidebar-widget-3.component.css']
})
export class SidebarWidget3Component implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('SIDEBAR-3 reporting for duty!');
  }

}
