import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
// I removed on SEPT 20 2022 
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { SidebarModule } from './sidebar/sidebar.module';
import { WidgetContainerComponent } from './sidebar/widget-container/widget-container.component';



// import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// ALLEY 3
import { IonicModule, NavController, IonApp, IonicRouteStrategy, LoadingController, Platform, IonNav } from '@ionic/angular';
import { UserProvider, AsyncPipeComponent } from '../app/reddit-data.service';
import { DataService } from '../app/data.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';


import { SpeedDialFabComponent } from '../app/speed-dial-fab/speed-dial-fab.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';
import { HometwoComponent } from './hometwo/hometwo.component';
import { NotFoundComponent } from './not-found.component';
import { HelloComponent } from './hello.component';
import { ExternalUrlDirective } from './external-url.directive';
import { HammerCardComponent } from './hammer-card/hammer-card.component';
import { HammertimeDirective } from './hammertime.directive';
import { MainNavComponent } from './main-nav/main-nav.component';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';

import * as Hammer from 'hammerjs';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { OverlayContainer } from '@angular/cdk/overlay';

import { CustomURLEncoder } from './urlencoder.component';

import {NgxPaginationModule} from 'ngx-pagination';

import { MySheetComponent } from './my-sheet/my-sheet.component';

import { SelectedPlayerComponent } from "./selected-player.component";


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: "pan-y"
    });

    return mc;
  }
}

@NgModule({
    declarations: [AppComponent,
        Page1Component,
        Page2Component,
        HometwoComponent,
        NotFoundComponent,
        HelloComponent,
        ExternalUrlDirective,
        HammerCardComponent,
        HammertimeDirective,
        MainNavComponent,
        SpeedDialFabComponent,
        WidgetContainerComponent,
        MySheetComponent,
        SelectedPlayerComponent
    ],
    entryComponents: [AppComponent,
        HometwoComponent,
        NotFoundComponent,
        Page1Component,
        Page2Component,
        MySheetComponent
    ],
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        LayoutModule,
        // I removed on SEPT 20 2022 
        BrowserModule,
        IonicModule.forRoot(), AppRoutingModule,
        BrowserAnimationsModule,
        HammerModule,
        MaterialModule,
        NgxPaginationModule,
        SidebarModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, HttpClient, Platform,
      CustomURLEncoder, StatusBar, SplashScreen, UserProvider, AsyncPipeComponent, DataService,
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}
    ],
    bootstrap: [AppComponent]
})
// THIS IS THERE BY DEFAULT.  I removed it because I copied and pasted the below
// export class AppModule {}
// PASTED BELOW
export class AppModule {
    constructor(overlayContainer: OverlayContainer) {
      overlayContainer.getContainerElement().classList.add('solution-dark-theme');
    }
  }
  //export class SpeedDialFabComponent { }
//   export class HomePageComponent {}