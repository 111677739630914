import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, Inject, Injectable,Renderer2, ElementRef, Input, NgZone,AfterViewInit, EventEmitter} from '@angular/core';
import { IonNav, NavController, Platform } from '@ionic/angular';
import { HomePage } from './home/home.page';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { DataService } from './data.service';
import { UserProvider, AsyncPipeComponent } from './reddit-data.service';

// ALLEY 3
import { FormGroup, FormBuilder, FormArray, Form, Validators, FormControl} from '@angular/forms';

// imported these to try to animate with hammerjs
import { trigger, keyframes, animate, transition, state,  style,
  animateChild,
  group,
  query,
  stagger,
  } from '@angular/animations';
import * as kf from './hammer-card/keyframes';

import { Router,  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart, } from '@angular/router';
  import {MediaMatcher} from '@angular/cdk/layout';

  import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';

import { HammerCardComponent } from './hammer-card/hammer-card.component';


import { DOCUMENT } from '@angular/common';
import { BROWSER_PROVIDER } from '@angular/flex-layout';

import { Constants } from './config/constants'; 


declare var myExtObject: any;
declare var webGlObject: any;

import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { mergeMap } from 'rxjs';

import {NestedTreeControl} from '@angular/cdk/tree';  
import {MatTreeNestedDataSource} from '@angular/material/tree';  


import { MatAccordion } from "@angular/material/expansion";

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';


import {COMMA, ENTER} from '@angular/cdk/keycodes';
// import {Component, ElementRef, ViewChild} from '@angular/core';
// import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

// import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

// import {Component} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from "@angular/material/table";
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MySheetComponent } from './my-sheet/my-sheet.component';


// import { Component, ViewChild, ElementRef} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
// import { FormControl } from '@angular/forms';
import { Observable, timer, Subject} from 'rxjs';
import { map } from 'rxjs/operators';

import {listAnimation, actionAnimation} from './animations';
import { QueryShake } from './query.animation'; 
// shopper


import {MatSort} from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';

import { BehaviorSubject, combineLatest } from "rxjs";

import { Player, PlayerValue } from "./config/models/player.model";

// export interface Fruit {
//   [name: string]: string|number|object[]

// }

interface Fruit {
  [key: string]: any;
}

// interface Dic {
//   [key: string]: Object[]
// }

export interface UserData {
  id: string;
  name: string;
  synonyms: object;
  score: number;
}

// RETAILERS START INTERFACE
export interface UserDataRetailers {
  id: string;
  name: string;
  score: number;
  itpCompatible: boolean;
  deeplinkSupport: boolean;
  hostDomain: string;
  mainUrl: string;
  collectiveTags: object;
  collectiveToolSupport: string;
  payoutStatus: string;
  confirmationWindow: object;
  cashBack: object;
}

// RETAILERSE END INTERFACE


const FRUITS: string[] = [
  'blueberry',
  'lychee',
  'kiwi',
  'mango',
  'peach',
  'lime',
  'pomegranate',
  'pineapple',
];
const NAMES: string[] = [
  'Maia',
  'Asher',
  'Olivia',
  'Atticus',
  'Amelia',
  'Jack',
  'Charlotte',
  'Theodore',
  'Isla',
  'Oliver',
  'Isabella',
  'Jasper',
  'Cora',
  'Levi',
  'Violet',
  'Arthur',
  'Mia',
  'Thomas',
  'Elizabeth',
];

interface shopperNode {
    id: shopperNode[];
    name: string;
    shortName?: string;
    fullName?: string;
    parentId?: string;
    hasHeelHeightFilter?: boolean;
    hasSizeFilter?: boolean;
    hasColorFilter?: boolean;
    showMaterialFilter?: boolean;
    children?: shopperNode[]; 
}

// end shopper

// vehicle tree

interface VehicleNode {
  name: string;
  id?: number;
  selected?: boolean;
  indeterminate?: boolean;
  children?: VehicleNode[];
  parent?: VehicleNode;
}

const TREE_DATA3: VehicleNode[] = [
  {
    name: "Infiniti",
    children: [
      {
        name: "G50",
        children: [
          { name: "Pure AWD", id: 1 },
          { name: "Luxe", id: 2 }
        ]
      },
      {
        name: "QX50",
        children: [
          { name: "Pure AWD", id: 3 }, 
          { name: "Luxe", id: 4 }
        ]
      }
    ]
  },
  {
    name: "BMW",
    children: [
      {
        name: "2 Series",
        children: [
          { name: "Coupé", id: 5 },
          { name: "Gran Coupé", id: 6 }
        ]
      },
      {
        name: "3 Series",
        children: [
          { name: "Sedan", id: 7 }, 
          { name: "PHEV", id: 8 }
        ]
      }
    ]
  }
];

// end vehicle tree

// start shopper tree
const TREE_DATA4: shopperNode[] = [
  {
    id:[],
    name:"Women's Fashion",
    shortName:"Women",
    fullName:"Women's Fashion",
    parentId:"clothes-shoes-and-jewelry",
    hasHeelHeightFilter:false,
    hasSizeFilter:false,
    hasColorFilter:true,
    showMaterialFilter:false,
    children:[]
    },
    {
    id:[
      {
        id:[],
        name:"Clothing",
        shortName:"Clothing",
        fullName:"Women's Clothes",
        parentId:"women",
        hasHeelHeightFilter:false,
        hasSizeFilter:true,
        hasColorFilter:true,
        showMaterialFilter:true,
        children:[]
        }
    ],
    name:"Clothing",
    shortName:"Clothing",
    fullName:"Women's Clothes",
    parentId:"women",
    hasHeelHeightFilter:false,
    hasSizeFilter:true,
    hasColorFilter:true,
    showMaterialFilter:true,
    children:[]
    }
];

// end shopper tree

export interface DialogData {
  myForm: FormBuilder;
  animal: string;
  name: string;
  description:string;
}



/** 
 * Food data with nested structure. 
 * Each node has a name and an optional list of children. 
 */  
 interface FoodNode {  
  name: string;  
  children?: FoodNode[];  
}  

const TREE_DATA1: FoodNode[] = [  
  {  
    name: 'Fruit',  
    children: [  
      {name: 'Apple'},  
      {name: 'Banana'},  
      {name: 'Fruit loops'},  
    ]  
  }, {  
    name: 'Vegetables',  
    children: [  
      {  
        name: 'Green',  
        children: [  
          {name: 'Broccoli'},  
          {name: 'Brussels sprouts'},  
        ]  
      }, {  
        name: 'Orange',  
        children: [  
          {name: 'Pumpkins'},  
          {name: 'Carrots'},  
        ]  
      },  
    ]  
  },  
];  

// interface FoodNode {
// 	name: string;
// 	children?: FoodNode[];
//    }

interface FoodNodeFlat {
	name: string;
	Id: Number;
	parentId: Number;
	children?: FoodNodeFlat[];
   }

//    const TREE_DATA: FoodNode[] = [
// 	{
// 	name: 'Fruit',
// 	children: [ {name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}, ]
// 	}, 
//    {
// 	name: 'Vegetables',
// 	children: [ { name: 'Green',
// 	children: [ {name: 'Broccoli'}, {name: 'Brussels sprouts'}, ]
// 	}, 
//    {
// 	name: 'Orange',
// 	children: [ {name: 'Pumpkins'}, {name: 'Carrots'}, ] },
// 	]
// 	},
//    ];
const TREE_DATA: FoodNodeFlat[] = [
	{ name: 'Fruit', Id: 1, parentId:null },
	{ name: 'Apple', Id: 2, parentId: 1 },
	{ name: 'Banana', Id: 3, parentId: 1 },
	{ name: 'Fruit loops', Id: 4, parentId: 1 }, 
	{ name: 'Vegetables', Id: 5, parentId:null },
	{ name: 'Green', Id: 6, parentId: 5 },
	{ name: 'Broccoli', Id: 7, parentId: 6 },
	{ name: 'Brussels sprouts', Id: 8, parentId: 6 }, 
	{ name: 'Orange', Id: 9, parentId: 5 },
	{ name: 'Pumpkins', Id: 10, parentId: 9 },
	{ name: 'Carrots', Id: 11, parentId: 9 },
	{ name: 'India', Id: 12, parentId: null },
	{ name: 'Maharashtra', Id: 13, parentId: 12 },
	{ name: 'Mumbai', Id: 14, parentId: 13 },
	{ name: 'Karnataka', Id: 15, parentId: 12 },
	{ name: 'Bangalore', Id: 16, parentId: 15 },
]

   /** Flat node with expandable and level information */
  interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	level: number;
   }


   export interface Card {
    Course: object;
  }
  
  const DATA: Card[] = [
    {
Course:{brandedName: "Milly Hannah Floral Sleeveless Maxi Dress",
clickURL: "https://api.shopstyle.com/action/apiVisitRetailer?id=934209845&pid=uid5321-992139-18&cuid=7906708",
description: "Milly Hannah Floral Sleeveless Maxi Dress-Women",
id: "6374985bd64a167d1e7e1345",
imageURL: "https://img.shopstyle-cdn.com/sim/6e/5d/6e5db12cc021df4bbe28b66238d22060_best/milly-hannah-floral-sleeveless-maxi-dress.jpg",
priceLabel: "$895",
productId: 934209845,
productName: "Milly Hannah Floral Sleeveless Maxi Dress",
productPrice: 895,
retailerBrand: "Milly",
retailerName: "Bloomingdale's",
unbrandedName: "Hannah Floral Sleeveless Maxi Dress"}
    }
   
  ];
  

 
function hello() {
  // alert('Hello!!!');
  	// set the red bar into 50% full
    
 
}


// declare const RPGUI: any;
declare const RPGUI: any;


declare function greet(): void;

// declare const myTest: any;
// declare var myTest: any;
declare const myTest: any;

// declare const myrest: any;
// declare function myrest(progressValue): any; // just change here from arun answer.
declare function myrest(param1: number): any;

// @Injectable()
// export class MyService {
//   constructor(@Inject(DOCUMENT) private document) {}
// }

declare function getToday():any;

declare function greetings(name:any):any;

type beacons = {aname:any} | any
declare const allbeacons: beacons


@Component({
  selector: 'app-root',
  templateUrl: './home/home.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./home/home.page.scss'],
  // added two below for hammerjs animation
  encapsulation: ViewEncapsulation.None,
  animations: [
    QueryShake,
    listAnimation,
    actionAnimation,
    trigger('enabledStateChange', [
      state(
        'default',
        style({
			opacity: 1,
		})
	),
	state(
		'disabled',
		style({
			opacity: 0.5,
        })
      ),
      transition('* => *', animate('300ms ease-out')),
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
    trigger("myTrigger", [
      state(
        "fadeInFlash",
        style({
          opacity: "1"
        })
      ),
      transition("void => *", [
        style({ opacity: "0", transform: "translateY(20px)" }),
        animate("500ms")
      ])
    ]),
    trigger('cardAnimator', [
      transition('* => wobble', animate(400, keyframes(kf.wobble))),
      transition('* => swing', animate(400, keyframes(kf.swing))),
      transition('* => jello', animate(400, keyframes(kf.jello))),
      transition('* => zoomOutRight', animate(400, keyframes(kf.zoomOutRight))),
      transition('* => slideOutLeft', animate(400, keyframes(kf.slideOutLeft))),
      transition('* => rotateOutUpRight', animate(400, keyframes(kf.rotateOutUpRight))),
      transition('* => flipOutY', animate(400, keyframes(kf.flipOutY))),
    ]),
    trigger('changeDivSize', [
      state('initial', style({
        width: '30px',
        height: '30px',
        opacity: '0.5',
        margin: '10px'
      })
      ),
      state('final', style({
        width: '32px',
        height: '32px',
        opacity: '1',
        margin: '10px'
      })),
      transition('initial<=>final', animate(400, keyframes(kf.wobble))),
      transition('final=>initial', animate(400, keyframes(kf.swing)))
    ]),
    trigger('focusPanel', [
      transition('* => wobble', animate(400, keyframes(kf.wobble)))
    ])
  ]
  // templateUrl: 'app.component.html',
  // styleUrls: ['app.component.scss'],
})

 
// export class AppComponent {
//   public appPages = [
//     { title: 'Inbo9x', url: '/folder/Inbox', icon: 'mail' },
//     { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
//     { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
//     { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
//     { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
//     { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
//   ];
//   public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
//   constructor() {}
// }

export class AppComponent implements OnInit {

  selectedMessage:any;

  public selectedIdValue$: BehaviorSubject<number>;
  public playersValue$: BehaviorSubject<PlayerValue[]>;
  public playerValue$: Observable<PlayerValue>;
  


  public selectedId$: BehaviorSubject<number>;
  public players$: BehaviorSubject<Player[]>;
  public player$: Observable<Player>;


  countChange(event) {
    console.log(event.value);
    this.setSelected(event.value);

    
    // this.selectedId$ = new BehaviorSubject(event.value);
    
  }

   person = { 
    firstname:"Tom", 
    lastname:"Hanks" 
 }; 


  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  fruits: Fruit[] = [
    {name : "the original"}
  ];

  // fl1: any;
  // retailerID: any;
  // limit: any;
  // searchText: any;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push({name: value});
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  
  

  // displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];
  displayedColumns: string[] = ['name'];
  dataSourceBrands: MatTableDataSource<UserData>;


  // ME TRYING TO ADD RETAILERS
  dataSourceRetailers: MatTableDataSource<UserDataRetailers>;


  // blocking this off in favor of a varable rename undo if it breaks
  @ViewChild(MatPaginator) paginatorBrandss: MatPaginator;
  @ViewChild('paginatorDataBrands') paginatorBrands: MatPaginator;


  // RETAILERS
  // blocking this off in favor of a varable rename undo if it breaks
  @ViewChild(MatPaginator) paginatorRetailerss: MatPaginator;
  @ViewChild('paginatorDataRetailers') paginatorRetailers: MatPaginator;
  // END RETAILERS


  @ViewChild(MatSort) sort: MatSort;


  control = new FormControl('');
  streets: any[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets: Observable<string[]>;

  animation: 'default' | 'withLimit' = null;


  // Disabling Animation by default
  flag: boolean = true;
  items = ["item 1", "item 2", "item 3"];
  // items: Observable<any>;
  // items: Subject<Item[]>;
  statez: string = "fadeInFlash";

  users = [
    { 
     
      name: 'Vincent Vega',
      occupation: 'Sceptic',
      membership: 'Admin',
      status: 'Active',
      created_at: '1994-11-03',
      url:'https://www.coditty.com/code/angular-animation-how-to-animate-single-item-from-ngfor-loop?utm_source=Stackblitz&utm_medium=Code&utm_campaign=Animation_List_and_Items'

    },
    { 
      
      name: 'Jules Winnfield',
      occupation: 'Earth walker',
      membership: 'User',
      status: 'Blocked',
      created_at: '1994-11-03',
      url:'https://www.coditty.com/code/angular-animation-how-to-animate-single-item-from-ngfor-loop?utm_source=Stackblitz&utm_medium=Code&utm_campaign=Animation_List_and_Items'

    },
    {
  
      name: 'Mia Wallace',
      occupation: 'Housewife',
      membership: 'User',
      status: 'Active',
      created_at: '1994-11-03',
      url:'https://www.coditty.com/code/angular-animation-how-to-animate-single-item-from-ngfor-loop?utm_source=Stackblitz&utm_medium=Code&utm_campaign=Animation_List_and_Items'

    },
    { 
       
      name: 'Butch Coolidge',
      occupation: 'Boxer',
      membership: 'Admin',
      status: 'Active',
      created_at: '1994-11-03',
      url:'https://www.coditty.com/code/angular-animation-how-to-animate-single-item-from-ngfor-loop?utm_source=Stackblitz&utm_medium=Code&utm_campaign=Animation_List_and_Items'

    },
    { 
   
      name: 'Winston Wolf',
      occupation: 'Problem solver',
      membership: 'Admin',
      status: 'Active',
      created_at: '1994-11-03',
      url:'https://www.coditty.com/code/angular-animation-how-to-animate-single-item-from-ngfor-loop?utm_source=Stackblitz&utm_medium=Code&utm_campaign=Animation_List_and_Items'

    }

  ];
  goods = [
    {
      name: 'voodoo1',
      charact: [
        {
          name: 'name 1'
        }
      ] 
    },
    {
      name: 'voodoo2',
      charact: [
        {
          name: 'name 2'
        }
      ] 
    },
    {
      name: 'voodoo3',
      charact: [
        {
          name: 'name 3'
        }
      ]
    }
  ];

  state = 'in';


  states = this.goods.map(() => 'in');

  // states: any;

  clickedRows: any;

  toggleClass: boolean = true;
  titlez: string;
  
  
  currentState: any;

  // dataToDisplay = [...DATA];
  dataToDisplay = DATA;
  // MatPaginator Inputs
  length = 100;
  pageSize = 6;
  pageSizeOptions: number[] = [1, 3, 6, 12, 24, 50];
  sliceLow = 0;
  sliceHigh = 0;

  // MatPaginator Output
  pageEvent: PageEvent;

  slidervalue: number = 12;
  retailerslidervalue: number = 1;


  myForm: FormGroup;
  animal: string;
  name: string;


 //readmore variable, its true than read more string will print
 ReadMore:boolean = true

 //hiding info box
 visible:boolean = false
  
  panelOpenState: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild('drawer') drawer: any;
 public selectedItem : string = '';
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));


 @Input() settings: any;


 // vehicle
 treeControlshopper = new NestedTreeControl<shopperNode>(node => node.id);
 dataSourceshopper = new MatTreeNestedDataSource<shopperNode>();
 // end vehicle


 // vehicle

 treeControlcar = new NestedTreeControl<VehicleNode>(node => node.children);
 dataSourcecar = new MatTreeNestedDataSource<VehicleNode>();

 // end vehicle

 treeControl = new NestedTreeControl<FoodNode>(node => node.children);  
 dataSource = new MatTreeNestedDataSource<FoodNode>();  
 // testing tree
private _transformer = (node: FoodNodeFlat, level: number) => {
	return {
	expandable: !!node.children && node.children.length > 0,
	name: node.name,
	level: level,
	};
	}
	// treeControl = new FlatTreeControl<ExampleFlatNode>(
	// 	node => node.level, node => node.expandable);
	//    treeFlattener = new MatTreeFlattener(
	// 	this._transformer, node => node.level, node => node.expandable, node => node.children);
	//    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

// end tree testing
 
  config = {
    panels: [
      {
        name: 'Section ddd1',
        description: 'First section',
        component: {
          selector: 'app-sidebar-widget-1',
          settings: {}
        }
      },
      {
        name: 'Section 2',
        description: 'Second section',
        component: {
          selector: 'app-sidebar-widget-2',
          settings: {}
        }
      },
      {
        name: 'Section 3',
        description: 'Third section',
        component: {
          selector: 'app-sidebar-widget-3',
          settings: {}
        }
      }
    ]
  };


  activePageTitle = 'Dashboard';
  Pages = [
    {
      title: 'Dashboard',
      url: '',
      icon: 'albums'
    },
    {
      title: 'Login',
      url: '/login',
      icon: 'person'
    },
    {
      title: 'Register',
      url: '/register',
      icon: 'person'
    }
  ];



  registrationForm: FormGroup;
  phoneTypes: string[] = ['Mobile','Home', 'Other'];
  get phoneNumbers(): FormArray{
    return this.registrationForm.get('phoneNumbers') as FormArray;
  }

  get firstNameControl(): FormControl {
    return this.registrationForm.get('firstNameControl') as FormControl;
  }
  get lastNameControl(): FormControl {
    return this.registrationForm.get('lastNameControl') as FormControl;
  }  
  get email(): FormControl {
    return this.registrationForm.get('email') as FormControl;
  }  
  // get promise(): FormControl {
  //   return this.registrationForm.get('promise') as FormControl;
  // }



  // nameControl = new FormControl('Ankit');
  // hobbyControl = new FormControl('Video Games');



  
  loadScript(url) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  
  // pretty sure the next two public statements will do nothing but were part of the original app template.
  // so i included them.  and it compiles... what to do?
  public appPages = [
    { title: 'Inbo9x', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    // random shit i'mdumpng in


    myrest: any;
    board: any;
    // name : string = "";
    c : number = 25;
    color = 'accent';
    mode = 'query';
    value = 10;
    bufferValue = 25;
    count : number = 999;
    mobileQuery: MediaQueryList;

    fillerNav = Array.from(
      {length: 5}, (_, i) => ` Dashboard ${i + 1}`);

      links: Array<{ text: string, path: string }> = [];

 loading = false;

  fillerContent = Array.from({length: 1}, () =>
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aum.`);

  private _mobileQueryListener: () => void;

  
    // end random shit

    imageItemCollection: any;

    title = 'AlleySix';
    avatars: any;
    listOfFoos: any;
    listOfFoosLength: any;
    listOfFoosOriginal: any;

    listOfCats: any;
    listOfCatsOriginal: any;
    listOfCatsLength: any; 

// BRANDS
listOfBrands: any;
listOfBrandsOriginal: any;
listOfBrandsLength: any;
// BRANDS END

// RETAILERS
listOfRetailers: any;
listOfRetailersOriginal: any;
listOfRetailersLength: any;
// RETAILERS END
    
    @ViewChild(IonNav) nav: IonNav;

   
  
    rootPage: any = HomePage;
  
    pages: Array<{ title: string, component: any }>;
  
   curse = {
    id:1,
    description: 'drink your ovaltine'
    
  };
  
  message:string;

  cartObject:object;
  allcats:object;
  catObject:object;
  brandObject:object;
  refinedCats:any;

  // RETAILER
  retailerObject:object;

  // END RETAILER
  
  // more random shit imported
  

  animationState: string;
	SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
	idx: any;
	// state: any;
  events: string[] = [];
  opened: boolean;
  replies:any;
dataPermiso:any;
dataPermisoCats:any;
dataPermisoBrands:any;

// RETAILERS
dataPermisoRetailers:any;
// END RETAILERS
index:any;

fooz:any;
  // end more random shit
  // constructor(private splashScreen: SplashScreen) { }
  @ViewChild('paginatorData') paginatorLegal: MatPaginator;
  obs: Observable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSourceCard: MatTableDataSource<Card> = new MatTableDataSource<Card>(DATA);
  
  @ViewChild('hello', { static: false }) divHello: ElementRef;
  @ViewChild(' rpgui-progress-fill red', { static: false }) divYahoo: ElementRef;
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
  @ViewChild('divCreateText', { static: false }) divCreateText: ElementRef;

    
    constructor(@Inject(DOCUMENT) private document: Document,
      private router: Router,
      private changeDetectorRef: ChangeDetectorRef,
      media: MediaMatcher,
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      private data: DataService,
      private datacats: DataService,
      private databrands: DataService,
      private dataretailers: DataService,
      private service: UserProvider,
      private el: ElementRef,
      private renderer: Renderer2,
      private fb: FormBuilder,
      private breakpointObserver: BreakpointObserver,
      public dialog: MatDialog,
      public ngZone: NgZone,
      private matBottomSheet: MatBottomSheet,
      private _bottomSheet: MatBottomSheet,
      private cdref: ChangeDetectorRef
    ) {
      this.initializeApp();


      // BRANDS
       // Create 100 users
    // const users = Array.from({length: 100}, (_, k) => this.createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    // this.dataSourceBrands = new MatTableDataSource(users);

      //ENDBRANDS

      this.dataSourcecar.data = TREE_DATA3;


      this.dataSource.data = TREE_DATA;  

      this.settings = {
        panels: [
          { name: 'zzz 1', description: 'First 111section' },
          { name: 'Sectibbccon 2', description: 'Second 222section' },
          { name: 'dfe 3', description: 'Third 333section' },
        ],
      };
    
      // console.log(Constants.TitleOfSite); 
      // console.log((new Constants).API_ENDPOINT);

      
      // i added the router block below

      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading = true;
            console.log('its TRUE');
            break;
          }
  
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading = false;
            console.log('its FALSE');
            break;
          }
          default: {
            break;
          }
        }
      });

      // i added
      let catresponse = this.data.currentCat.subscribe(datacats => {
        if(datacats) this.catObject = this.catObject;

        console.log('CAT data below');
        console.log(datacats);
        console.log(this.datacats.currentMessage);
        console.log('CAT data UP UP');

          // then extract the data from response over here
            // assign the extracted 'data' array to a component variable named data.
      this.catObject = datacats;

        this.newCatGet(datacats);





        });
      // I REMOVE TEMP
      // 1_CALLS API MUST LOOK INTO
      // DISABLED TO REDUCE API CALLS 09-25-2022
      // this.loadPermiso('14');

      // change this for retailer
      // DISABLED TO REDUCE API CALLS 09-25-2022
      // this.data.changeMessage('21');
      // 1_CALLS API MUST LOOK INTO
      // this.data.changeMessage('THE_QUICK_BROWN_FOX');

// BRAND START
      // i added
      let brandresponse = this.data.currentBrand.subscribe(databrands => {
        if(databrands) this.brandObject = this.brandObject;

        console.log('BRANDS data below');
        console.log(databrands);
        console.log(this.databrands.currentBrand);
        console.log('BRANDS data UP UP');

          // then extract the data from response over here
            // assign the extracted 'data' array to a component variable named data.
      this.brandObject = databrands;

        this.newBrandGet(databrands);





        });
// BRAND END


// RETAILERS START
      // i added
      let retailerresponse = this.data.currentRetailer.subscribe(dataretailers => {
        if(dataretailers) this.retailerObject = this.retailerObject;

        console.log('RETAILERS data below');
        console.log(dataretailers);
        console.log(this.dataretailers.currentRetailer);
        console.log('RETAILERS data UP UP');

          // then extract the data from response over here
            // assign the extracted 'data' array to a component variable named data.
      this.retailerObject = dataretailers;

        this.newRetailerGet(dataretailers);





        });
// RETAILERS END

     
      let response = this.data.currentMessage.subscribe(data => {
        if(data) this.cartObject = this.cartObject;

        console.log('loadpermiso data below');
        // console.log(data);
        // console.log(this.data.currentMessage);
        console.log('loadpermiso data UP UP');

          // then extract the data from response over here
            // assign the extracted 'data' array to a component variable named data.
      this.cartObject = data;
      // console.log(data);
      // console.log( data["limit"] );

        // const paramIDS = data["ids"];
        // const paramLimit = data["limit"];
        // const paramFL1 = data["limit"];
        // const paramRetailerId = data["retailerID"];

        // console.log(data["searchText"]);



        // DISABLED TO REDUCE API CALLS 09-25-2022
        // this.loadPermiso(data);
        this.newMassageGet(data);

        // this.loadPermiso(data);

        // param-1 ?IDS is a duplicate
        // param-2 = how many to return
        // param-3 = fl0 whatever that is
        // param-4 = retailer ID
        // this.loadPermiso(paramIDS, paramLimit, paramFL1, paramRetailerId);
      
        });

        console.log(this.cartObject);

        this.router.config.unshift(
          //  { path: 'home', component: HometwoComponent },
            { path: 'page1', component: Page1Component },
            { path: 'page2', component: Page2Component },
            { path: 'home',
            loadChildren: () => import('./home/home.module').then(x => x.HomePageModule),
            data: {animation: 'AnimationsPage'}  
            },
            {
              path: 'about',
              loadChildren: () => import('./about/about.module').then(x => x.AboutModule)
            },
            {
              path: 'example',
              loadChildren: () => import('./example/example.module').then(x => x.ExampleModule)
            },
            {
              path: 'media',
              loadChildren: () => import('./media/media.module').then(x => x.MediaModule)
            },
          );

          this.links.push(

            { text: 'page1', path: 'page1' },
            { text: 'page2', path: 'page2' },
            { text: 'home', path: 'home' },
            { text: 'invalidpath', path: 'p3threeage2' },
            { text: 'about', path: 'about' },
            { text: 'example', path: 'example' },
            { text: 'media', path: 'media' },
          );
          
          
      //i added end


  
      
          // used for an example of ngFor and navigation
      this.pages = [
        { title: 'Home', component: HomePage },
        { title: 'foo', component: HammerCardComponent }
      ];
    }

    // vehicle haschild
    hasChildshopper = (_: number, node: shopperNode) => !!node.id && node.id.length > 0;
    // end haschild vehicle

    // vehicle haschild
    hasChildcar = (_: number, node: VehicleNode) => !!node.children && node.children.length > 0;
    // end haschild vehicle


    hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;  


    // Method
    initializeForm(): void {
      this.registrationForm = this.fb.group({
        firstNameControl: ['', [Validators.required,Validators.minLength(2)]],
        lastNameControl: ['', Validators.required],
        phoneNumbers: this.fb.array([this.createPhoneGroup()]),
        email: ['', Validators.required,Validators.email],
        subscribe: true,
        address: this.fb.group({
          street: '',
          city: '',
          state: '',
          zipCode: ''
        })
        // ,
        // promise: [false,Validators.requiredTrue]

      }
      );


    }

    addPhoneNumber(): void {
      this.phoneNumbers.push(this.createPhoneGroup());
      console.log(this.phoneNumbers);
    }

    removePhoneNumber(index: number){
      this.phoneNumbers.removeAt(index);
      console.log(this.phoneNumbers);
    }

    createPhoneGroup() {
      return this.fb.group({
        phoneNumber: '',
        phoneType: ''
      });
    }

    pickPhoneType(event){
      this.registrationForm.patchValue({
        phoneType: event.target.value
      })

    }

    // This is called a Method.  Seems like a function to me.
    changeName() {
      // this.nameControl.setValue('koolmoo');
      console.log('ChangeName Pressed');

    }

    submitForm(){
      console.log(this.registrationForm);
    }
  
    initializeApp() {
      this.platform.ready().then(() => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        console.log('iniy APPPPPPPPPPPPPPPPPPPPPPPPPPPPP');
      });
    }
    
  
    openPage(page) {
      // Reset the content nav to have just this page
      // we wouldn't want the back button to show in this scenario
      this.nav.setRoot(page.component);
    }

    
    // hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  
    ngOnInit() {

      this.data.changeMessageAny("NGONIT - DEFAULT MESSAGE on changeMessageAny in Parent");

      console.log('HEYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY')


      // SEND TO SELECT PLAYER
      this.selectedIdValue$ = new BehaviorSubject(1);
      this.playersValue$ = new BehaviorSubject([
        { id: 1, name: "moo"}
      ]);


      this.playerValue$ = combineLatest([this.playersValue$, this.selectedIdValue$]).pipe(
        map(([players, id]) => players.find(player => player.id === id))
      );
      // END SEND



      this.selectedId$ = new BehaviorSubject(3);
      this.players$ = new BehaviorSubject([
        { id: 1, name: "Mario" },
        { id: 2, name: "Luigi" },
        { id: 3, name: "Toad" },
        { id: 4, name: "Bowser" }
      ]);
  
      this.player$ = combineLatest([this.players$, this.selectedId$]).pipe(
        map(([players, id]) => players.find(player => player.id === id))
      );

      // this.dataSourceBrands.filterPredicate = this.createFilter();

      // IF YOU UNCOMMENT YOU LOSE THE CARDS
      // this.dataSourceBrands.filterPredicate = this.customFilterPredicate();


      // this.dataSourceBrands.filterPredicate = function(data, filter: string): boolean {

 
      //   const obj = data;
    
      //   const facets = obj.id;
      //   const result = Object.keys(data).find(v => data[v].id === filter);
        
      //   //your object keys are equal to id, you can just return key
      //   console.log(result);
        
      //   // if your object keys can be different from id you can do this
      //   console.log(obj[result].id);

        
      //   let obja = this.listOfBrands.find(o => o.id === filter);
      //   console.log(obja);

      //   // let frru = data.id.

      //   var moth = this.listOfBrands.find((el, idx) => typeof el === "string" && idx === 2);
      //   console.log(moth);

      //   var foundValue = this.listOfBrands.filter(obj => data[obj.id] === filter);


      //   return foundValue;


      //   // return data.name.toLowerCase().includes(filter) || data.symbol.toLowerCase().includes(filter);
      //   // return data.id.toLowerCase().includes(filter);
      // };


      this.filteredStreets = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

      this.users = this.users.map((user) => {


        
        user.status = 'orig';
        return user;
    });

      this.changeDetectorRef.detectChanges();
      this.dataSourceCard.paginator = this.paginator;
      this.obs = this.dataSourceCard.connect();

// not sure if this done anything, it was in ngafterinit before
      // this.dataSourceBrands.paginator = this.paginator;
      // this.dataSourceBrands.sort = this.sort;

      // not sure if above does anything


      this.myForm = this.fb.group({
        name: new FormControl(null),
        animal: new FormControl(null)
      })

      console.log(this.refinedCats);
		// this.dataSource.data = TREE_DATA;
		// console.log(TREE_DATA);
		// console.log(this.dataSource.data);
		this.dataSource.data =this.treeConstruct(TREE_DATA);


      this.initializeForm();

      // this.renderer.setProperty(this.divHello.nativeElement,'innerHTML',"Hello Angular")
      // this.renderer.setStyle(this.divHello.nativeElement, 'color', 'red');
      // this.renderer.setStyle(this.divHello.nativeElement, 'color', 'red');

      // hello();
      this.loadScript('../assets/rpgui/rpgui.js');

      // this.data.currentMessage.subscribe(message => this.message = message);

    // I REMOVED BELOW 10-17-2022 - seems to do nothing.  data is still loaded.
    //   this.data.currentMessage.subscribe(cartObject => {
    //     if(cartObject) this.cartObject = cartObject; 
    //  });
    //  console.log(this.cartObject);
     
    //  this.data.changeMessage({
    //   good: true,
    //   mace: "fffffffffff"
    // });
    
    // this.newMassageGet(this.cartObject);


      //i added and I REMOVED BELOW 10-17-2022 - 
      // this.imageItemCollection = [
      //   {
      //     url: "https://images.unsplash.com/photo-1512672378591-74fbb56b1d28?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=111881731843c98860fd6ede341337d7&auto=format&fit=crop&w=1350&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1486495939893-f384c2860f55?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=bf36a4694839666ab094bcdd0bb88651&auto=format&fit=crop&w=1350&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1514913274516-4aa04f176f8c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a6940b0c53d64fc564bed31bb6aa8d9b&auto=format&fit=crop&w=1760&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1523286877159-d9636545890c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f44497f72d77b9e8e27e87521e025edc&auto=format&fit=crop&w=1351&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1459886757952-87e191b82aeb?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=6c977d9f0c074c220a31f1e89449c3aa&auto=format&fit=crop&w=1350&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1519423961530-9131478718db?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e6132d79c5060ba00caa99cf39457da6&auto=format&fit=crop&w=1350&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1482510356941-d087154c2931?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cd6c067c548407960ec92f1e820775ee&auto=format&fit=crop&w=1355&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1520507215037-061ed0f37178?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=0b0ee4f4dcd684859da448cc26c707a2&auto=format&fit=crop&w=1350&q=80",
      //     show: false
      //   },
      //   {
      //     url: "https://images.unsplash.com/photo-1522447984233-657d56c465d8?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b2efa4e73b38094995897590487ba5b4&auto=format&fit=crop&w=1350&q=80",
      //     show: false
      //   }
      // ];

      // this.avatars = [
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1512672378591-74fbb56b1d28?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=111881731843c98860fd6ede341337d7&auto=format&fit=crop&w=1350&q=80",
      //     visible: true
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1486495939893-f384c2860f55?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=bf36a4694839666ab094bcdd0bb88651&auto=format&fit=crop&w=1350&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1514913274516-4aa04f176f8c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a6940b0c53d64fc564bed31bb6aa8d9b&auto=format&fit=crop&w=1760&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1523286877159-d9636545890c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f44497f72d77b9e8e27e87521e025edc&auto=format&fit=crop&w=1351&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1459886757952-87e191b82aeb?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=6c977d9f0c074c220a31f1e89449c3aa&auto=format&fit=crop&w=1350&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1519423961530-9131478718db?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e6132d79c5060ba00caa99cf39457da6&auto=format&fit=crop&w=1350&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1482510356941-d087154c2931?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cd6c067c548407960ec92f1e820775ee&auto=format&fit=crop&w=1355&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1520507215037-061ed0f37178?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=0b0ee4f4dcd684859da448cc26c707a2&auto=format&fit=crop&w=1350&q=80",
      //     visible: false
      //   },
      //   {
      //     name: 'kristy',
      //     image: "https://images.unsplash.com/photo-1522447984233-657d56c465d8?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b2efa4e73b38094995897590487ba5b4&auto=format&fit=crop&w=1350&q=80",
      //     visible: false
      //   }
      // ];
    
    

      // end i added

      


    }

    ngOnDestroy() {
      if (this.dataSource) { 
        this.dataSource.disconnect(); 
      }
    }


    setUserRole(status,user){

      /*
        NOTE: here is important to not update this.users because then we can see flickering, but to update user item passed in function and grabbed from ngFor loop    
      */ 
  
      // set animation state
      user.state = 'small';
  
      // here we are making timeout to simulate request to backend
      setTimeout(() => {
  
          // change value
          user.membership = status;
  
          // back to original state
          user.state = 'orig';
  
      },300);
  
    }
  
    setUserStatus(status,user){
      // set animation state
      user.state = 'small';
  
      // here we are making timeout to simulate request to backend
      setTimeout(() => {
  
          // change value
          user.status = status;
  
          // back to original state
          user.state = 'orig';
  
      },300);
  
    }

    toggleChar(i: number,idx: number) {

      // state = 'in';

console.log(this.goods);
      // states = this.goods.map(() => 'in');
    
      timer(200).subscribe(x => { 
        /* Use destructuring to make a copy */
        this.states[i] = this.states[i] === 'in' ? 'out' : '';

console.log(i);
console.log(idx);
      /* Use destructuring to make a copy */
      console.log('imade it here');

  
        
     this.resetAnimationState(i);

      const newData = [ ...this.dataSourceCard.data ]; 

      
      newData.splice(i,1);
      this.dataSourceCard.data = newData;

      
       console.log(newData);
      this.dataSourceCard = new MatTableDataSource(newData);
      console.log (this.dataSourceCard);
     

       this.dataSourceCard.data = newData;

       this.changeDetectorRef.detectChanges();
       this.dataSourceCard.paginator = this.paginator;

       this.animationState = 'wobble';

       
    console.log(this.animationState);

       return this.obs = this.dataSourceCard.connect();    

      })

      

  
    }


    addItem() {
      if (this.flag) {
        // Enabling Animation
        this.cdref.detectChanges();     

        this.flag = !this.flag;
      }
      this.items.push("another item");
    } 

    startAnimation1(state: any, current: any, idx: number) {


      if (!this.animationState) {

     
console.log(current);

        // this.animationState = state;
        console.log(this.animationState);
    

        console.log(state);
 
    
        this.animationState = state;
 
      if (this.animationState === 'wobble') {
 
    console.log('wobbling? wobbling!');
    timer(200).subscribe(x => { 
       /* Use destructuring to make a copy */
     const newData = [ ...this.dataSourceCard.data ]; 
     newData.splice(idx,1);
     this.dataSourceCard.data = newData;

 
     })

    }


     
    
    }
    

    



  }


  animate(): void {
    this.animation = 'default';
  }

  animateWithLimit(): void {
    this.animation = 'withLimit';
  }

  animationDone(): void {
    this.animation = null;
  }
    
    
    onTriggerSheetClick(){
      // this.matBottomSheet.open();
      console.log('HELLO THERE');

    
    }

    openBottomSheet(): void {
      this._bottomSheet.open(MySheetComponent);
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
      if (setPageSizeOptionsInput) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
      }
    }

    changeClass(actionType) {
  
      switch (actionType) {
        case 'attack':
          console.log('I GIVE YOU AN ATTACK');
          this.toggleClass = !this.toggleClass;

          timer(800).subscribe(x => { 
            /* Use destructuring to make a copy */
            this.toggleClass = !this.toggleClass;
          })
          break;
        case 'peace':
          console.log('I GIVE YOU PEACE');
          this.toggleClass = !this.toggleClass;

          timer(800).subscribe(x => { 
            /* Use destructuring to make a copy */
            this.toggleClass = !this.toggleClass;
          })
          break;
        default:
          console.log('I DO NOT EXISTGUESS NOT thanks!');
      }
         
    }
 
  

      // This method will be called whenever we switch a page, add or delete item, and init at the begining
  loadPage(event?:PageEvent) {
    
 console.log('EVENT FIRED');

      if(event){
        this.sliceLow = event.pageIndex * event.pageSize
        this.sliceHigh = this.sliceLow + event.pageSize
      } else {
        this.sliceLow = 0
        this.sliceHigh = this.pageSize
      }
 
    return event
  }
    
    
//constructTree recursively iterates through the tree to create nested tree structure.
  //We only need Id and parentId Columns in the flat data to construct this tree properly.
  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
  // console.log(treeObj);
if (treeObj.parentId == treeObj.id) { //was null
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.Id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }  
  

    // testing DOM
    newMassageGet(cartObject: any){
      this.service.gett(cartObject).subscribe(data => {
         console.log('PRODUCT RESPONSE');
         console.log(data);
         console.log('PRODUCT END RESPONSE');
         //  this.listOfFoos = data;
         //   this.listOfFoosOriginal = data;
         // this.listOfFoosLength = data.length;
         this.listOfFoos = data;
         this.listOfFoosOriginal = data;
         this.listOfFoosLength = data.length;

         // set length of mat-paginator
         this.length = data.length;
         
         
        //  const DATA: Card[] = this.listOfFoos;
                 //  console.log(TREE_DATA);
                 let myarrs = Object.keys(data).map(function(personNamedIndex){
                  let person = data[personNamedIndex];
                 // console.log(data[personNamedIndex]);
                 // console.log(person)
       
                 // do something with person
                 const DATA: Card[] = [
                  {Course: data}];
                //  const DATA: Card[] = [
                //    {
                //     brandedName: data[personNamedIndex].brandedName,
                //     clickURL: data[personNamedIndex].clickURL,
                //     description: data[personNamedIndex].description,
                //     id: data[personNamedIndex].id,
                //     imageURL: data[personNamedIndex].imageURL,
                //     priceLabel: data[personNamedIndex].priceLabel,
                //     productId: data[personNamedIndex].productId,
                //     productName: data[personNamedIndex].productName,
                //     productPrice: data[personNamedIndex].productPrice,
                //     retailerBrand: data[personNamedIndex].retailerBrand,
                //     retailerName: data[personNamedIndex].retailerName,
                //     unbrandedName: data[personNamedIndex].unbrandedName,
                //   }]
                  // brandedName: "Milly Hannah Floral Sleeveless Maxi Dress",
                  // clickURL: "https://api.shopstyle.com/action/apiVisitRetailer?id=934209845&pid=uid5321-992139-18&cuid=7906708",
                  // description: "Milly Hannah Floral Sleeveless Maxi Dress-Women",
                  // id: "6374985bd64a167d1e7e1345",
                  // imageURL: "https://img.shopstyle-cdn.com/sim/6e/5d/6e5db12cc021df4bbe28b66238d22060_best/milly-hannah-floral-sleeveless-maxi-dress.jpg",
                  // priceLabel: "$895",
                  // productId: 934209845,
                  // productName: "Milly Hannah Floral Sleeveless Maxi Dress",
                  // productPrice: 895,
                  // retailerBrand: "Milly",
                  // retailerName: "Bloomingdale's",
                  // unbrandedName: "Hannah Floral Sleeveless Maxi Dress"
            
                 // return TREE_DATA;
                 return person;
                // return DATA;
             });
            

             const DATA: Card[] = myarrs
             this.dataSourceCard.data = DATA;  
             console.log(this.dataSourceCard);
             console.log(DATA);


  // this.states = this.dataSourceCard.data.map(() => 'in');


            
         this.dataPermiso= data;
         return this.dataPermiso;
         //console.log(this.listOfFoos);
         //return this.listOfFoos;
         });
  
  
    }

    newCatGet(catObject: any){
      this.service.gettcat(catObject).subscribe(data => {
         console.log('CAT RESPONSE');
         console.log(data);
         console.log(typeof(data));

         console.log('CAT END RESPONSE');

         this.listOfCats = data;
         this.listOfCatsOriginal = data;
         this.listOfCatsLength = data.length;
  
         this.dataPermisoCats= data;


        //  console.log(TREE_DATA);
         let myarrs = Object.keys(data).map(function(personNamedIndex){
           let person = data[personNamedIndex];
          // console.log(data[personNamedIndex]);
          // console.log(person)

          // do something with person
          const TREE_DATA: FoodNodeFlat[] = [
            { name: data[personNamedIndex].name, Id: data[personNamedIndex].id, parentId:data[personNamedIndex].parentId }]


          // return TREE_DATA;
          return person;
      });
         
         function flatten(arr) {

          return arr.reduce((acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur), []);
      
      };
      
     
      
      const flattened = flatten(myarrs);
      
       console.log(myarrs)

         console.log(flattened);


        //  const TREE_DATA: FoodNodeFlat[] = [flattened];

        const nest = (items, id = this.listOfCats.parentId, link = this.listOfCats.id) =>
        items
        .filter(item => item[link] === id)
        .map(item => ({
          ...item,
          children: nest(items, item.id)
        }));
 
        function listToTree(data, options) {
          options = options || {};
          var ID_KEY = options.idKey || 'id';
          var PARENT_KEY = options.parentKey || 'parent';
          var CHILDREN_KEY = options.childrenKey || 'children';
          
          var item, id, parentId;
          var map = {};
          for(var i = 0; i < data.length; i++ ) { 
            if(data[i][ID_KEY]){
              map[data[i][ID_KEY]] = data[i];
              data[i][CHILDREN_KEY] = [];
              // data[i]['myid'] = 'dddd';
              // console.log(data[i])
            }
          }
          for (var i = 0; i < data.length; i++) {
            if(data[i][PARENT_KEY]) { // is a child
              if(map[data[i][PARENT_KEY]]) // for dirty data
              {
                map[data[i][PARENT_KEY]][CHILDREN_KEY].push(data[i]); // add child to parent
                data.splice( i, 1 ); // remove from root
                i--; // iterator correction
              } else {
                data[i][PARENT_KEY] = 0; // clean dirty data
              }
            }
          };
          return data;
        }


        console.log(listToTree(this.listOfCats, {
          idKey: 'id',
          parentKey: 'parentId',
          childrenKey: 'id'
        }));

              //  this.dataSource.data =this.treeConstruct(myarrs);
              // UNDID THIS TEMP
              // const TREE_DATA: FoodNode[] = listToTree(this.listOfCats, {
              //   idKey: this.listOfCats.id,
              //   parentKey: this.listOfCats.parentId,
              //   childrenKey: 'children'
              // });

              const TREE_DATA: shopperNode[] = listToTree(this.listOfCats, {
                idKey: this.listOfCats.id,
                parentKey: this.listOfCats.parentId,
                childrenKey: this.listOfCats.id
              });
              
        console.log(TREE_DATA);

        function flattenReduce(arr) {
          return arr.reduce(
            (acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur),
            []
          );
        }
        const flattenedReduce = flattenReduce(this.listOfCats);
        console.log(flattenedReduce)



     
        // console.log(nest(TREE_DATA))
        // console.log(TREE_DATA)
        console.log(this.treeConstruct(TREE_DATA))
        // console.log(JSON.stringify(TREE_DATA));
console.log(TREE_DATA1)
        
this.dataSourceshopper.data = TREE_DATA;
        // this.dataSourceshopper.data = flattenedReduce;
        // this.dataSource.data = (TREE_DATA);

        // 
	// { name: 'Fruit', Id: 1, parentId:null },
	// { name: 'Apple', Id: 2, parentId: 1 },
        

        // UNDO THIS THIS IS A TEST UNLESS TREE ADATA WORKS
        // return TREE_DATA;
        // return TREE_DATA; 
        // this.dataSource.data =this.treeConstruct(TREE_DATA);
        //  return this.dataPermisoCats;

         });

       
  
    }
    
    areYou() {
      // this.data.currentMessageAny.subscribe(message => (this.selectedMessage= message)); //<= Always get current value!
  
      // this.data.changeMessageAny("wow wow wow wow ckkdkd here");
  
  console.log('AREYOU IN PARENT FIRED');
      var currentAny = this.data.currentAny.subscribe(message => {
        if(message){

        // console.log('cccccccccccccc data below');
        this.selectedMessage = message;
        // console.log(message);
        console.log('current message is:');
        console.log(this.selectedMessage);
        // console.log(this.data.currentBrand);
        // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkk data UP UP');
  
          // then extract the data from response over here
            // assign the extracted 'data' array to a component variable named data.
      // this.brandObject = databrands;
  
        // this.newBrandGet(databrands);
  
  

        } else {

          console.log('Nothing valuable was found.  message is false in areYou');

        }
  
  
  
  
        });
  // BRAND END
     }
  
     areYouSet() {
      // this.data.currentMessageAny.subscribe(message => (this.selectedMessage= message)); //<= Always get current value!
  
      // this.data.changeMessageAny("wow wow wow wow ckkdkd here");
  
  console.log('AREYOUSET IN PARENT FIRED');

  ///
  this.data.changeMessageAny("PARENT - areYouSet fired, the message has been set by PARENT");
    


  let smells = this.data.getCurrentMessageAny();

  if(smells){
    console.log('IT SMELLS: ' + smells);
  } else {
    console.log('Nothing valuable was found.  message is false in areYouSet');

  }
  ///
      
  
  
  
        ;
  // BRAND END
     }

    newBrandGet(brandObject: any){
      this.service.gettbrand(brandObject).subscribe(data => {
         console.log('BRAND RESPONSE');
         console.log(data);
         console.log(typeof(data));

         console.log('BRAND END RESPONSE');

         this.listOfBrands = data;
         this.listOfBrandsOriginal = data;
         this.listOfBrandsLength = data.length;
        //  this.streets = data;
         const mapped = data.map(({ name }) => ( name));
         console.log(mapped);
         this.streets = mapped;


          // Assign the data to the data source for the table to render


// sort ascending (1 to X)
data.sort((a, b) => {  
  return a.name >= b.name
    ? 1
    : -1
})

        //  this.dataSourceBrands = new MatTableDataSource(data.slice(0,2120));
        this.dataSourceBrands = new MatTableDataSource(data);
         this.dataSourceBrands.paginator = this.paginatorBrands;
         this.dataSourceBrands.sort = this.sort;
          

  
         this.dataPermisoBrands= data;


        //  console.log(TREE_DATA);
         let myarrs = Object.keys(data).map(function(personNamedIndex){
           let person = data[personNamedIndex];
          // console.log(data[personNamedIndex]);
          // console.log(person)

          // do something with person
          const TREE_DATA: FoodNodeFlat[] = [
            { name: data[personNamedIndex].name, Id: data[personNamedIndex].id, parentId:data[personNamedIndex].parentId }]


          // return TREE_DATA;
          return person;
      });
         
         function flatten(arr) {

          return arr.reduce((acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur), []);
      
      };
      
     
      
      const flattened = flatten(myarrs);
      
       console.log(myarrs)

         console.log(flattened);


        //  const TREE_DATA: FoodNodeFlat[] = [flattened];

        const nest = (items, id = this.listOfCats.parentId, link = this.listOfCats.id) =>
        items
        .filter(item => item[link] === id)
        .map(item => ({
          ...item,
          children: nest(items, item.id)
        }));
 
        function listToTree(data, options) {
          options = options || {};
          var ID_KEY = options.idKey || 'id';
          var PARENT_KEY = options.parentKey || 'parent';
          var CHILDREN_KEY = options.childrenKey || 'children';
          
          var item, id, parentId;
          var map = {};
          for(var i = 0; i < data.length; i++ ) { 
            if(data[i][ID_KEY]){
              map[data[i][ID_KEY]] = data[i];
              data[i][CHILDREN_KEY] = [];
              // data[i]['myid'] = 'dddd';
              // console.log(data[i])
            }
          }
          for (var i = 0; i < data.length; i++) {
            if(data[i][PARENT_KEY]) { // is a child
              if(map[data[i][PARENT_KEY]]) // for dirty data
              {
                map[data[i][PARENT_KEY]][CHILDREN_KEY].push(data[i]); // add child to parent
                data.splice( i, 1 ); // remove from root
                i--; // iterator correction
              } else {
                data[i][PARENT_KEY] = 0; // clean dirty data
              }
            }
          };
          return data;
        }


        console.log(listToTree(this.listOfCats, {
          idKey: 'id',
          parentKey: 'parentId',
          childrenKey: 'id'
        }));

              //  this.dataSource.data =this.treeConstruct(myarrs);
              // UNDID THIS TEMP
              // const TREE_DATA: FoodNode[] = listToTree(this.listOfCats, {
              //   idKey: this.listOfCats.id,
              //   parentKey: this.listOfCats.parentId,
              //   childrenKey: 'children'
              // });

              const TREE_DATA: shopperNode[] = listToTree(this.listOfCats, {
                idKey: this.listOfCats.id,
                parentKey: this.listOfCats.parentId,
                childrenKey: this.listOfCats.id
              });
              
        console.log(TREE_DATA);

        function flattenReduce(arr) {
          return arr.reduce(
            (acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur),
            []
          );
        }
        const flattenedReduce = flattenReduce(this.listOfCats);
        console.log(flattenedReduce)



     
        // console.log(nest(TREE_DATA))
        // console.log(TREE_DATA)
        console.log(this.treeConstruct(TREE_DATA))
        // console.log(JSON.stringify(TREE_DATA));
console.log(TREE_DATA1)
        
this.dataSourceshopper.data = TREE_DATA;
        // this.dataSourceshopper.data = flattenedReduce;
        // this.dataSource.data = (TREE_DATA);

        // 
	// { name: 'Fruit', Id: 1, parentId:null },
	// { name: 'Apple', Id: 2, parentId: 1 },
        

        // UNDO THIS THIS IS A TEST UNLESS TREE ADATA WORKS
        // return TREE_DATA;
        // return TREE_DATA; 
        // this.dataSource.data =this.treeConstruct(TREE_DATA);
        //  return this.dataPermisoCats;

         });

       
  
    }
    


// RETAILERS
    
newRetailerGet(retailerObject: any){
  this.service.gettretailer(retailerObject).subscribe(data => {
     console.log('RETAILER RESPONSE');
     console.log(data);
     console.log(typeof(data));

     console.log('RETAILER END RESPONSE');

     this.listOfRetailers = data;
     this.listOfRetailersOriginal = data;
     this.listOfRetailersLength = data.length;
    //  this.streets = data;
     const mapped = data.map(({ name }) => ( name));
     console.log(mapped);
     this.streets = mapped;


      // Assign the data to the data source for the table to render


// sort ascending (1 to X)
data.sort((a, b) => {  
return a.name >= b.name
? 1
: -1
})

    //  this.dataSourceBrands = new MatTableDataSource(data.slice(0,2120));
     this.dataSourceRetailers = new MatTableDataSource(data);
     this.dataSourceRetailers.paginator = this.paginatorRetailers;
     this.dataSourceRetailers.sort = this.sort;
      


     this.dataPermisoRetailers= data;


    //  console.log(TREE_DATA);
     let myarrs = Object.keys(data).map(function(personNamedIndex){
       let person = data[personNamedIndex];
      // console.log(data[personNamedIndex]);
      // console.log(person)

      // do something with person
      const TREE_DATA: FoodNodeFlat[] = [
        { name: data[personNamedIndex].name, Id: data[personNamedIndex].id, parentId:data[personNamedIndex].parentId }]


      // return TREE_DATA;
      return person;
  });
     
     function flatten(arr) {

      return arr.reduce((acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur), []);
  
  };
  
 
  
  const flattened = flatten(myarrs);
  
   console.log(myarrs)

     console.log(flattened);


    //  const TREE_DATA: FoodNodeFlat[] = [flattened];

    const nest = (items, id = this.listOfCats.parentId, link = this.listOfCats.id) =>
    items
    .filter(item => item[link] === id)
    .map(item => ({
      ...item,
      children: nest(items, item.id)
    }));

    function listToTree(data, options) {
      options = options || {};
      var ID_KEY = options.idKey || 'id';
      var PARENT_KEY = options.parentKey || 'parent';
      var CHILDREN_KEY = options.childrenKey || 'children';
      
      var item, id, parentId;
      var map = {};
      for(var i = 0; i < data.length; i++ ) { 
        if(data[i][ID_KEY]){
          map[data[i][ID_KEY]] = data[i];
          data[i][CHILDREN_KEY] = [];
          // data[i]['myid'] = 'dddd';
          // console.log(data[i])
        }
      }
      for (var i = 0; i < data.length; i++) {
        if(data[i][PARENT_KEY]) { // is a child
          if(map[data[i][PARENT_KEY]]) // for dirty data
          {
            map[data[i][PARENT_KEY]][CHILDREN_KEY].push(data[i]); // add child to parent
            data.splice( i, 1 ); // remove from root
            i--; // iterator correction
          } else {
            data[i][PARENT_KEY] = 0; // clean dirty data
          }
        }
      };
      return data;
    }


    console.log(listToTree(this.listOfCats, {
      idKey: 'id',
      parentKey: 'parentId',
      childrenKey: 'id'
    }));

          //  this.dataSource.data =this.treeConstruct(myarrs);
          // UNDID THIS TEMP
          // const TREE_DATA: FoodNode[] = listToTree(this.listOfCats, {
          //   idKey: this.listOfCats.id,
          //   parentKey: this.listOfCats.parentId,
          //   childrenKey: 'children'
          // });

          const TREE_DATA: shopperNode[] = listToTree(this.listOfCats, {
            idKey: this.listOfCats.id,
            parentKey: this.listOfCats.parentId,
            childrenKey: this.listOfCats.id
          });
          
    console.log(TREE_DATA);

    function flattenReduce(arr) {
      return arr.reduce(
        (acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur),
        []
      );
    }
    const flattenedReduce = flattenReduce(this.listOfCats);
    console.log(flattenedReduce)



 
    // console.log(nest(TREE_DATA))
    // console.log(TREE_DATA)
    console.log(this.treeConstruct(TREE_DATA))
    // console.log(JSON.stringify(TREE_DATA));
console.log(TREE_DATA1)
    
this.dataSourceshopper.data = TREE_DATA;
    // this.dataSourceshopper.data = flattenedReduce;
    // this.dataSource.data = (TREE_DATA);

    // 
// { name: 'Fruit', Id: 1, parentId:null },
// { name: 'Apple', Id: 2, parentId: 1 },
    

    // UNDO THIS THIS IS A TEST UNLESS TREE ADATA WORKS
    // return TREE_DATA;
    // return TREE_DATA; 
    // this.dataSource.data =this.treeConstruct(TREE_DATA);
    //  return this.dataPermisoCats;

     });

   

}



// END RETAILERS

    
    clickedRowsBrand(row: any){
      console.log(row.name);
      console.log(row.id);
      // this.fruits.push({name: row.name});


      let findme = this.listOfBrands.find(objectItem => objectItem.id === row.id.toString());
      console.log(findme);
      //find the brand and add it
if(findme.hasOwnProperty('id')){
  console.log("yes, i have that BRAND in HERE");
  console.log(findme.name);
  // this.fruits.push(
  //   {"name": "FL1: " + currentValue.fl1}
  // )

// FINDER





// END FINDER
  
  // this.fruits.splice(5, 1, {"name": "BRAND MCGOO: " + findme.name});


  // update and execute
  this.data.changeValue({brandID: row.id});

}

// THIS IS THE ORIGINAL AND WORKS FINE BUT DISABLED FOR FINDME
  // this.fruits.splice(this.fruits.length, 0, {"name": "BRAND Beee: " + row.name})

      // this.data.changeValue({brandID: row.id});
    }

    getCurrentValue(){
      // let terr = {id: '223344'};
      console.log(this.value);
      // this.fruits.push({name: this.value});
      // const currentValue = this.data.getCurrentMessage();
      // console.log(currentValue);
      
      var currentValue: Fruit = this.data.getCurrentMessage();
      console.log(currentValue);
// console.log(this.dataSourceBrands.);


this.dataSourceBrands.filter = '14';
// this.dataSourceBrands.filter.trim();
console.log(this.dataSourceBrands.filteredData);

      
      if(currentValue.hasOwnProperty('fl1')){
          console.log("yes, i have that property FL1");
          console.log(currentValue.fl1);
          // this.fruits.push(
          //   {"name": "FL1: " + currentValue.fl1}
          // )

          this.fruits.splice(0, 1, {"name": "FILTER 0"})

      }
      if(currentValue.hasOwnProperty('retailerID')){
        console.log("yes, i have that property reatilerID");
        console.log(currentValue.retailerID);

        //filter
        const alligatorFacts = ["thick scales", 80, "4 foot tail", "rounded snout", 80];
let moth = this.listOfRetailers.filter(function(item){
  var y: number = +currentValue.retailerID;
  console.log(y);

  return item.id == y;         
});

console.log(this.listOfRetailers);

var y: number = +currentValue.retailerID;
console.log(y);

// let letsee = this.searchest(3,this.listOfRetailers);
// console.log(letsee);

let item1 = this.listOfRetailers.find(objectItem => objectItem.id === currentValue.retailerID.toString());
// console.log(item1);




// console.log(this.listOfFoos[0].retailerName);



// let moth = this.listOfBrands.find((el, idx) => typeof el === "string" && idx === 2);
//  let mothed = this.listOfRetailers.find((el, idx) => typeof el === "string" && idx === currentValue.retailerID);
// console.log(mothed);
// console.log(moth[0].id);


        //end filter

        let item2 = item1;
        // console.log(item2);
        
        if (item2) {
          console.log('HEY YOUR IN THE CLUB');
          this.fruits.splice(1, 1, {"name": "RETAILER REAL: " + item2.name})
          
          // this.fruits.push(
          //   {"name": "RETAILER: " + currentValue.retailerID},
          //   {"name": "RETAILER REAL: " + item2.name}
          // )
        }
        else {
          console.log('YOU GOT TO PAY THE TOLL');
          this.fruits.splice(1, 1, {"name": "RETAILER MIMIC: " + this.dataPermiso[0].retailerName})
          // this.fruits.push(
          //   {"name": "RETAILER: " + currentValue.retailerID},
          //   {"name": "RETAILER MIMIC: " + this.listOfFoos[0].retailerName}
          // )
        }

        // this.fruits.push(
        //   {"name": "RETAILER: " + currentValue.retailerID},
        //   {"name": "RETAILER REAL: " + moth[0].name}
        // )

    }

    if(currentValue.hasOwnProperty('limit')){
      console.log("yes, i have that property LIMIT");
      console.log(currentValue.limit);
      // this.fruits.push(
      //   {"name": "FL1: " + currentValue.fl1}
      // )

      this.fruits.splice(2, 1, {"name": "LIMIT: " + currentValue.limit})

  }

  if(currentValue.hasOwnProperty('cat')){
    console.log("yes, i have that property CATEGORY");
    console.log(currentValue.cat);
    // this.fruits.push(
    //   {"name": "FL1: " + currentValue.fl1}
    // )

    this.fruits.splice(3, 1, {"name": "CATEGORY: " + currentValue.cat})

}

if(currentValue.hasOwnProperty('searchText')){
  console.log("yes, i have that property searchText");
  console.log(currentValue.searchText);
  // this.fruits.push(
  //   {"name": "FL1: " + currentValue.fl1}
  // )

  this.fruits.splice(4, 1, {"name": "searchText: " + currentValue.searchText})

}
// searchText

//brand id
if(currentValue.hasOwnProperty('brandID')){
  console.log("yes, i have that BRAND ID searchText");
  console.log(currentValue.brandID);
  // this.fruits.push(
  //   {"name": "FL1: " + currentValue.fl1}
  // )

  let brandme = this.listOfBrands.find(objectItem => objectItem.id === currentValue.brandID.toString());


  this.fruits.splice(5, 1, {"name": "BRAND ME: " + brandme.name})

}


      // this.fruits.push(currentValue);
    }



    openDialog() {

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
  
      dialogConfig.data = {
          id: 1,
          title: 'Angular For Beginners',
          name: this.name,
          animal: this.animal,
          data: this.myForm.value
      };

      dialogConfig.position = {
        top: '15px'
      }
  
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was mooooooooooooo!');
            this.animal = result;
            this.myForm.controls.animal.setValue({name: 'hey you ', animal: result});
            console.log(result)
            console.log(this.animal);

            interface MyObjLayout {
              animal: object;
              name:string
          }
          
          var obj: MyObjLayout = this.myForm.controls.animal.value;


          console.log(this.myForm.controls.animal.value)
          let userName = this.myForm.controls.animal.value.animal;
          console.log(userName.animal);
          this.newMassage({searchText: userName.animal})

            console.log(this.myForm);
            // this.newMassage({
            //   searchText: this.animal
            // });
            
          }
        // data => console.log("Dialog output:", data)
    );  

  }

    
    // openDialog(): void {
    //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
    //     width: '250px',
    //     data: {name: this.name, animal: this.animal},
    //   });
  
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log('The dialog was closedddddddddddddddddddddddddddd!');
    //     this.animal = result;
    //     console.log(result)
    //     console.log(this.animal);
    //   });
    // }
    


    
    newMassage(cartObject: any){
      this.data.changeValue(
        cartObject
      );
    }


   
    // more testing
    exampleDiv1() {
      // const e = this.renderer.selectRootElement('.div1',false); 	 	   
      const e = this.renderer.selectRootElement('.rpgui-progress-track',false); 	 	
   }
   
    exampleDiv2() {
      //Conent is always replaced. becuase preserveContent is false
      const e = this.renderer.selectRootElement('.div2',false); 	 	   
      const t = this.renderer.createText('Content added to div2');
      this.renderer.appendChild(e, t);
 
    }
 
    exampleDiv3() {
      //Conent is always appended. becuase preserveContent is true
      const e = this.renderer.selectRootElement('.div3',true); 	 	   
      const t = this.renderer.createText('Content added to div3');
      this.renderer.appendChild(e, t);
    }
    // more end testing

  setStyle() {
    this.renderer.setStyle(this.divHello.nativeElement, 'color', 'orange');

    const progress = this.document.getElementById('red-bar');
    this.renderer.setStyle(progress,'left', '0px');
    this.renderer.setStyle(progress,'width', '25%');
    // progress.style.width = (1 * 65) + "%";
    
    progress.style.width = (1 * 100) + "%";
    progress.style.left = "0px";

    const bro = this.document.querySelectorAll('#red-bar > div');
    console.log(bro);
    // document.querySelectorAll('#red-bar > div').forEach(el => el.remove());



    var usedIds = {};
    var arrow = [];

    for(let i = 0; i < bro.length; i++) {
        const id = bro[i].getAttribute("id");
        if(usedIds[id]) {
          console.log(i);
            // bro[i].parentNode.removeChild(bro[i]);
            arrow.push(bro[i]);


             
        } else {
            usedIds[id] = true;
        }
    }

 
    let arr = [arrow];
    let obj = {};
    
    const unique = () => {
      let result = [];
      
      arr.forEach((item, i) => {
        obj[item['value']] = i;
      });
      
      for (let key in obj) {
        let index = obj[key];
        result.push(arr[index])
      }
      
      return result;
    }
    
    arr = unique(); // for example; 
    
    console.log(arr);

    bro[0].parentNode.removeChild(bro[0]);

  }
  removeStyle() {
    this.renderer.removeStyle(this.divHello.nativeElement, 'color');
  }



  addClass() {
    this.renderer.addClass(this.divHello.nativeElement, 'blackborder' );
  }
  removeClass() {
    this.renderer.removeClass(this.divHello.nativeElement, 'blackborder');
  }
  


  addAttribute() {
    this.renderer.setAttribute(this.inputElement.nativeElement, 'value', 'cool text' );
  }
  removeAttribute() {
    this.renderer.removeAttribute(this.inputElement.nativeElement, 'value');
  }


  setProperty() {
    this.renderer.setProperty(this.divHello.nativeElement,'innerHTML',"Hello Angular")
  }


  createText() {
   const text = this.renderer.createText('Example of Create Text');
   this.renderer.appendChild(this.divCreateText.nativeElement, text);
  }
  
  createElement() {
    const div = this.renderer.createElement('div');
    const text = this.renderer.createText('Inserted at bottom');

    this.renderer.appendChild(div, text);
    this.renderer.appendChild(this.el.nativeElement, div);
  }


    // END DOM TESTING


    togglePanel() {
      this.panelOpenState = !this.panelOpenState
      }

      myButton() {
        console.log("my button was clicked!");
      }

    // i added
    openedChange(val) {
      console.log('openedChange: ', val);
    }

  //onclick toggling both
  onclickSearch()
  {
    this.openDialog();
     //not equal to condition.  this shows and hides 'readmore'
    // this.ReadMore = !this.ReadMore;

    // this shows and hides 'visible'
    // this.visible = !this.visible 
  }

    onClick() {
 
      // getToday();
      // myrest(0.2);
      
      const progress = this.document.getElementById('red-bar');
      console.log('pro');
      console.log(progress);
      console.log(RPGUI.author);
     
      // myTest();

      // RPGUI('red-bar',{ progress: 0.5 });
      // console.log(gag);

      RPGUI.set_value(progress, 0.6);
      // console.log(RPGUI.set_value(progress, 0.6));
    

    }

    onClick2(){
      myTest();
    }

  onChange(idx) {
    console.log(idx);
    this.index =  idx;
  }

  oldMessage(sid:number) {
    this.data.changeMessage("MESSAGE_OLDMESSAGE");
    console.log('app component click! - THUMBS UP');
		console.log(sid);


		const index: number = this.listOfFoos.indexOf(sid);
			console.log('index is:  ' + index);
		if (sid !== -1) {

		this.listOfFoos.splice(sid,1);
		console.log('index is greater than -1');
		console.log(this.listOfFoos);
		}

// 		this.imageItemCollection.shift();
// 		console.log(this.imageItemCollection);

	}

  futureMessage(sid:number) {
    this.data.changeMessage("MESSAGE_FUTUREMESSAGE");
    console.log('app component click! - THUMBS DOWN!');
		console.log(sid);

		const index: number = this.listOfFoos.indexOf(sid);
			console.log('index is:  ' + index);
		if (sid !== -1) {

		this.listOfFoos.splice(sid,1);
		console.log('index is greater than -1');
		console.log(this.listOfFoos);
		}

// 		this.listOfFoos.splice(this.listOfFoos.indexOf(sid),1_);


	}

  GoodGoodMessage(sid) {
    // this.data.changeMessage("Hey im' inside app.c new.mesg");
    console.log('app component clicked on fab button');
    console.log(sid);
console.log(this.data.currentMessage);
console.log('FAB BUTTON FIRED');
  //  this.loadPermiso('33');
  this.data.changeMessage((Math.floor(Math.random() * 60) + 1).toString());

  let response = this.data.currentMessage.subscribe(data => {
console.log(data);
// this.loadPermiso(data);

});


    //
		// const index: number = this.listOfFoos.indexOf(sid);
		// 	console.log('index is:  ' + index);
		// if (sid !== -1) {
    //
		// this.listOfFoos.splice(sid,1);
		// console.log('index is greater than -1');
		// console.log(this.listOfFoos);
		// }

    //      //LEGACY
// let response = this.service.gett('88').subscribe(data => {
// 	console.log(data.length);
// 	});

  // 	//LEGACY


  }
    // end i added

    includeCat(nodeName) {
      console.log(this.cartObject);
      const myObject: {[key: string]: any} = this.cartObject;
    
    myObject.cat = nodeName;
    console.log(myObject)
      console.log('you gave me:  ' + nodeName);
 
      
      // let calla = this.newMassage(nodeName);
      //

      // console.log(this.data.currentMessage);

      
    this.data.changeValue(myObject);
  
  
  
  console.log('GOD INCLUDE CAT CALLING COMPONENT');
  
    // let response = this.data.currentMessage.subscribe(data => {
    // console.log(data);
   
  
    // });
   

    }
  
    newMessage(sid) {
      this.data.changeMessage("23");
      console.log('app component click!');

      // i added
      console.log(sid);

      
		const index: number = this.listOfFoos.indexOf(sid);
    console.log('index is:  ' + index);
  if (sid !== -1) {

  this.listOfFoos.splice(sid,1);
  console.log('index is greater than -1');
  console.log(this.listOfFoos);
  }
  // end i added
      
      //      //LEGACY
  let response = this.service.gett({
    ids: 21,
    limit: 12,
    fl1: 0,
    retailerID: 28
  }).subscribe(data => {
    console.log('w');
    console.log(data.length);
    console.log('end w');
    });
    
    // 	//LEGACY
   
      
    }


    refresh(): void {
      this.applySomeModif();
      // Do what you want with dataSource
  
      this.dataSourceCard.data = this.dataSourceCard.data;
  }
  
  applySomeModif(): void {
      // add some data
      // this.dataSourceCard.data.push(new Card());
      // delete index number 4
      this.dataSourceCard.data.splice(4, 0);
  }

  trackByFn(index: any,Course: { id: any; }){
    // console.log(index);
    // return item.id;
    return Course ? Course.id : undefined;
  }

  // trackEmployee(index: any,information: { id: any; }){
  //   return information ? information.id : undefined;
  // }

 // i added
//  toggleChar(i: number) {
//   this.states[i] = this.states[i] === 'in' ? 'out' : '';
// }

 startAnimation(state: string, idx: number) {
  this.states[idx] = this.states[idx] === 'in' ? 'out' : '';
  
const data = { a: 1, b: 2, c: 3 };

const removeProp = 'b';

const { [removeProp]: remove, ...rest } = data;

console.log(remove); // 2
console.log(rest); // { a: 1, c: 3 }

console.log(this.dataSourceCard);
console.log(this.listOfFoosOriginal[1]);

 /* Use destructuring to make a copy */
//  const newData = [ ...this.dataSourceCard.data ]; 
//  newData.splice(idx,1);
//  this.dataSourceCard.data = newData;
console.log(state);

  if (!this.animationState) {

    const currentIndex: number = this.listOfFoos.indexOf(idx);
      console.log('PAss IF OK! currentIndex is : ' + currentIndex);
      console.log(currentIndex);

    let nextIndex = 0;
      console.log(state);
      console.log(idx);
      console.log(this.listOfFoos.length);

    this.animationState = state;
    console.log(this.animationState);

    // toggleChar
    // this.animationState === 'wobble'
  if (this.animationState === 'slideOutLeft' || this.animationState === 'wobble') {
//  block of code to be executed if condition1 is true
//  return this.swipe(this.animationState);
//  console.log(state);

timer(200).subscribe(x => { 
   /* Use destructuring to make a copy */
 const newData = [ ...this.dataSourceCard.data ]; 
 newData.splice(idx,1);
 this.dataSourceCard.data = newData;
 })


      console.log('SLIDE LEFT OK!')
      console.log('whoop there it is : LEFT INDEX BEFORE: ' + this.listOfFoos.length);

 var printError = function(error, explicit) {
      console.log(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`);
  }
    try {
    //     this.listOfFoos.length = this.listOfFoos.length - 1;

      console.log(`Length is: ${this.listOfFoos.length}`);

      const isFirst = currentIndex === 0;
      nextIndex = isFirst ? this.listOfFoos.length - 1 : currentIndex - 1;
       this.listOfFoos.splice(idx,1);
    } catch (e) {
      if (e instanceof RangeError) {
        if (e.message.toLowerCase().indexOf('invalid array') !== -1) {
          printError(e, true);
        } else {
          printError(e, false);
        }
        } else {
          printError(e, false);
        }
      }

      console.log('whoop there it is : LEFT INDEX AFTER: ' + this.listOfFoos.length);


      // progress bar attempt START
      const progress = this.document.getElementById('red-bar');
      console.log('pro');
      console.log(progress);
      // console.log(RPGUI.author);
     
      // myTest();

      // RPGUI('red-bar',{ progress: 0.5 });
      // console.log(gag);
      console.log(this.idx);
      RPGUI.set_value(progress, (this.listOfFoos.length / 50));
      // console.log(RPGUI.set_value(progress, 0.6));


      // progress bar attempt END
    // toggle avatar visibility

    console.log(this.listOfFoos);

      this.listOfFoos.forEach((x, i) => x.visible = (i === nextIndex));
    // this.avatars.forEach((x, i) => x.visible = (i === nextIndex));

} else if (this.animationState === 'slideOutRight' || this.animationState === 'swing') {
    //  block of code to be executed if the condition1 is false and condition2 is true
    // return this.swipe(this.animationState);
    //  console.log(state);
      console.log('SLIDE RIGHT OK!')
      console.log('whoop there it is : RIGHT INDEX BEFORE: ' + this.listOfFoos.length);

    if ((this.listOfFoos.length >= this.listOfFoosLength)) {        // logical condition
      console.log('this is equal or greater than ' + this.listOfFoosLength);              // executed if condition is true
    } else {                                // else block is optional
      console.log('this is LESS than ' + this.listOfFoosLength);
    // this.listOfFoos.length = this.listOfFoos.length + 1; // executed if condition is false

      const isLast = currentIndex === this.listOfFoos.length - 1;
      nextIndex = isLast ? 0 : currentIndex + 1;
      this.listOfFoos.forEach((x, i) => x.visible = (i === nextIndex));
      //this.listOfFoos.splice(idx,1);
      console.log('original idx value : ' + this.listOfFoosOriginal);
      console.log(this.listOfFoosOriginal[1]);
      console.log('IDX value is: ' + idx);
      //this.listOfFoos.slice(idx, 0,  this.listOfFoosOriginal[1]);
    }

      console.log('whoop there it is : RIGHT INDEX AFTER: ' + this.listOfFoos.length);



    console.log(this.listOfFoos);
    // toggle avatar visibility

    // this.listOfFoos.forEach((x, i) => x.visible = (i === nextIndex));

  } else {
      console.log('whooops!');
    //  block of code to be executed if the condition1 is false and condition2 is false
  }

}
}

resetAnimationState(i) {
this.animationState = '';

this.states[i] = this.states[i] === 'out' ? 'in' : '';
// this.currentState[i] = this.currentState[i]  === 'initial' ? 'final' : 'initial';
}
 
changeState(index): void{
  console.log(index);
  // ORIGINAL
  // this.currentState[index] = this.currentState[index]  === 0 ? 'final' : 'initial';
  //  this.currentState[index] = this.currentState[index]  === 'initial' ? 'final' : 'initial';
  // this.states[index] = this.states[index] === 'in' ? 'out' : 'in';

  this.states[index] = this.states[index] === '' ? 'out' : 'fly';

  // this.states = this.dataSourceCard.data.map(() => 'in');
console.log(this.states);

// console.log(this.states[index]);

this.animationState = this.states[index];
  // this.startAnimation1('wobble',this.states, index)

}



 // end i added
 closeSideNav() {
  if (this.drawer._mode=='over') {
    this.drawer.close();
  }
}
    public loadPermiso(
      thisdata
      // ids: number,
      // searchText: string, NOT THIS
      // limit: number,
      // fl1: number,
      // retailer: number
      ){

       

      //this.service.gett('45').subscribe(data => {
           this.service.gett(thisdata).subscribe(data => {
              console.log('CCCCCCCCCCCCC RESPONSE');
              console.log(data);
              console.log('CCCCCCCCCCCCCCCC RESPONSE');
              //  this.listOfFoos = data;
              //   this.listOfFoosOriginal = data;
              // this.listOfFoosLength = data.length;
              this.listOfFoos = data;
               this.listOfFoosOriginal = data;
             this.listOfFoosLength = data.length;
      
              this.dataPermiso= data;
              return this.dataPermiso;
              });
      
      
            }

 // Custom filter method fot Angular Material Datatable
 createFilter() {
  let filterFunction = function (data: any, filter: string): boolean {
  let searchTerms = JSON.parse(filter);
  let isFilterSet = false;
  for (const col in searchTerms) {
      if (searchTerms[col].toString() !== '') {
      isFilterSet = true;
      } else {
      delete searchTerms[col];
      }
  }

  let nameSearch = () => {
      let found = false;
      if (isFilterSet) {
      for (const col in searchTerms) {
          searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
          if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
              found = true
          }
          });
      }
      return found
      } else {
      return true;
      }
  }
  return nameSearch()
  }
  return filterFunction
}


customFilterPredicate() {
  const myFilterPredicate = function(data:any, filter:string) :boolean {
    let searchString = JSON.parse(filter);
    let nameFound = data.name.toString().trim().toLowerCase().indexOf(searchString.name.toLowerCase()) !== -1
    let positionFound = data.id.toString().trim().indexOf(searchString.id) !== -1
    if (searchString.topFilter) {
        // return nameFound || positionFound 
        return positionFound
    } else {
        // return nameFound && positionFound
        return positionFound
    }
  }
  return myFilterPredicate;
}



// add
reincrement(numnum: string){
  this.count = this.count+1;
  console.log('STRINGEY ADD');
}

increment(){
  this.count = this.count+1;
  console.log('COUNT ADD');
}
decrement(){
  this.count = this.count - 1;
    console.log('COUNT MINUS ');
}

swipe(sid) {
this.data.changeMessage("Hey im' inside ANimationState!");
console.log('WING DAMAGE!');
console.log(sid);



const index: number = this.listOfFoos.indexOf(sid);
console.log('index is:  ' + index);
if (sid !== -1) {

this.listOfFoos.splice(sid,1);
console.log('index is greater than -1');
console.log(this.listOfFoos);
return this.listOfFoos;
}

}

hyperswipe(action, sid) {
  const currentIndex: number = this.listOfFoos.indexOf(sid);

       if (currentIndex > this.listOfFoos.length || currentIndex < 0) return;
       console.log(currentIndex);
       let nextIndex = 0;

       // next
       if (action === this.SWIPE_ACTION.RIGHT) {
           const isLast = currentIndex === this.listOfFoos.length - 1;
           nextIndex = isLast ? 0 : currentIndex + 1;
       }

       // previous
       if (action === this.SWIPE_ACTION.LEFT) {
           const isFirst = currentIndex === 0;
           nextIndex = isFirst ? this.listOfFoos.length - 1 : currentIndex - 1;
       }

       // toggle avatar visibility
       this.listOfFoos.forEach((x, i) => x.visible = (i === nextIndex));

   }

// was godMessage() before.  renamed it because i had another function with the same name
   goadMessage() {
    this.data.changeMessage("HOME IS WHERE IT IS");
    console.log('HOME RUN!  click!');
    
	location.href = "https://espn.com";
	// window.open("https://yahoo.com");
 
    
  }

  openNewWindow(clickURL): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = clickURL;
    link.click();
    link.remove();
    console.log('AND AWAY WE GO');

  

      const progress = this.document.getElementById('red-bar');
      console.log('pro');
      console.log(progress);
     
      // myTest();

      // this.RPGUI('red-bar',{ progress: 0.5 });
      // console.log(this.board);

  
      // RPGUI.set_value(progress, 0.2);
    
//  myrest(0.7);
// myTest();
// greet();


  }

  openNewWindowNoWhere(clickURL): void {
    const link = this.document.createElement('a');
    link.href = clickURL;
    link.click();
    link.remove();
    console.log('YOU AINT GOING ANYWHERE');

    // myrest(0.7);
    // myTest();
  }


  ruuluu(): void {


      const progress = this.document.getElementById('red-bar');
      RPGUI('red-bar',{
        progress: "0.2"
      });
      console.log(this.board);

  
      // RPGUI.set_value(progress, 0.2);
    
 


  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

// end add
ngAfterViewInit() {
  // this.dataSourceBrands.paginator = this.paginator;
  // this.dataSourceBrands.sort = this.sort;
}

applyFilter(event: { target: HTMLInputElement }) {


  switch (true) {
    case event instanceof HTMLInputElement: {
      this.loading = true;
      console.log('its TRUE');
      console.log(event);
      break;
    }
    

    
    default: {
      console.log('noooooo');
      break;
    }
  }

  
console.log(event.target.value);
  const filterValue = (event.target as HTMLInputElement).value;
  // const filterValue = 'mango';
  this.dataSourceBrands.filter = filterValue.trim().toLowerCase();

  if (this.dataSourceBrands.paginator) {
    this.dataSourceBrands.paginator.firstPage();
  }


}

// createNewUser(id: number): UserData {
//   const name =
//     NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
//     ' ' +
//     NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
//     '.';

//   return {
//     id: id.toString(),
//     name: name,
//     progress: Math.round(Math.random() * 100).toString(),
//     fruit: FRUITS[Math.round(Math.random() * (FRUITS.length - 1))],
//   };
// }
setSelected(id: number) {
  this.selectedId$.next(id);
}
  
  } // end app component on init

  @Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialog-overview-example-dialog.html',
  })
  export class DialogOverviewExampleDialog {

    myForm: FormGroup;
    description:string;
    animal:string;
    name:string;
    msg = '';

    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, private ngZone: NgZone,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private fb: FormBuilder,
       
        // @Inject(MAT_DIALOG_DATA) data
        ) {
        //   this.myForm = this.fb.group({
        //     description: [this.description, []],
        //     animal: [this.animal, []],
        // });
          this.name = data.name;
          this.animal = data.animal;
          this.description = data.description;
    }
    changeAnimal(ev: any) {
      this.data.animal = ev.target.value;
    }

    ngOnInit() {
        this.myForm = this.fb.group({
            description: [this.description, []],
            animal: [this.animal, []],
        });
    }

    save() {
      console.log('you made it here god damn');
      // console.log(this.myForm);
        // this.dialogRef.close(this.myForm.value);
        // this.dialogRef.close(this.myForm.controls.animal.setValue('aaaaaaaaaaaaaaaaaaaaaas'));
        

        this.dialogRef.close(this.data)
    }

    close(): void {
        // this.dialogRef.close();
        // this.nameControl.setValue('koolmoo');
        this.ngZone.run(() => {
          this.dialogRef.close(this.myForm.patchValue({data: "you lose", animal: 'jasmine', description: 'des is de description'}));
          console.log('YOU CLOSE!');
        });
     
    }
   
    handleSubmit(e){
      e.preventDefault();
      this.save();
      // alert(this.msg);
    }
    handleKeyUp(e){
      if(e.keyCode === 13){
         this.handleSubmit(e);
      }
   }
  
    cancel() {
      // closing itself and sending data to parent component
      this.dialogRef.close({ data: 'you cancelled' })
    }
  
    confirm() {
      // closing itself and sending data to parent component
      this.dialogRef.close({ data: 'you confirmed' })
    }
    
    // onNoClick(): void {
    //   this.dialogRef.close();
    // }
    onNoClick(): void {
      this.ngZone.run(() => {
        console.log('smoke you');
        this.dialogRef.close();
      });
    }
  }
  