// app/core/course.model.ts
import { Injectable } from '@angular/core';
// import { Adapter } from './adapter';
import { AdapterBrands } from './adapterbrands';

export class CourseBrands {
    // ...

constructor(
	public id: string,
	public name: string,
	public synonyms: object,
	public score: number,
){}


}

@Injectable({
    providedIn: 'root'
})
export class CourseBrandsAdapter implements AdapterBrands<CourseBrands> {

  adapt(item: any): CourseBrands {
    return new CourseBrands(
      item.id,
			item.name,
			item.synonyms,
      item.score
    );
  }
}