// app/core/course.model.ts
import { Injectable } from '@angular/core';
// import { Adapter } from './adapter';
import { AdapterRetailers } from './adapterretailers';

export class CourseRetailers {
    // ...

constructor(
	public id: string,
	public name: string,
  public score: number,
  public itpCompatible: boolean,
  public deeplinkSupport: boolean,
  public hostDomain: string,
  public mainUrl: string,
  public collectiveTags: object,
  public collectiveToolSupport: string,
  public payoutStatus: string,
	public confirmationWindow: object,
  public cashBack: object
){}


}

@Injectable({
    providedIn: 'root'
})
export class CourseRetailersAdapter implements AdapterRetailers<CourseRetailers> {

  adapt(item: any): CourseRetailers {
    return new CourseRetailers(
      item.id,
			item.name,
      item.score,
      item.itpCompatible,
      item.deeplinkSupport,
      item.hostDomain,
      item.mainUrl,
      item.collectiveTags,
      item.collectiveToolSupport,
      item.payoutStatus,
      item.confirmationWindow,
      item.cashBack
    );
  }
}