import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IonicModule, NavController, LoadingController } from '@ionic/angular';
import { UserProvider, AsyncPipeComponent } from '../../app/reddit-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
// import { Subscription } from 'rxjs/Subscription';


// import { Http } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { DataService } from '../data.service';








declare const myTest: any;


function hello() {
  alert('Hello!!!');
  	// set the red bar into 50% full
	// var progress = document.getElementById("red-bar");
	// wrapperMethods.RPGUI.set_value(progress, 0.5);
}




// ALLEY 3

@Component({
   selector: 'page-home',
//selector: 'async-pipe',
//   template: `
// 	{{message}} - mmmmm
// 	`,
	

	
 templateUrl: 'home.page.html',

 changeDetection: ChangeDetectionStrategy.OnPush


})


 
export class HomePage implements OnInit {


	onClick() {
		myTest();
	  }

posts: any;
message:string;
cartObject:object;

@Input('data') meals: string[] = [];
    page: number = 1;
// public myUser: Array<any>;
// public posts: Array<any>;

//film: any;
response: any;
ip: any;
//myUser = [];
courseObs: Observable<any>;
// public courseObs: any;
// public courseObs: Observable<Course>;

		results: Observable<any>;
			model: any = {};
			

http: any;
loader: any;
 
IUser = [];
show: boolean = true;

public aqi: any;

public promiseData: string;
v: any;

UserProvider: any;
passes: any;
asses: any;

title: any;

observableData: number;
subscription: Object = null;

public listOfFoos: any;
  
public bases$: any;

// public bases$: Observable<any[]>

 curse = {
	id:1,
	description: 'drink your ovaltine'
	
};

    users: any = [1,2,3,4,5]
    loading: any;
	
    username: string;
    password: string;

    constructor(public navCtrl: NavController, public loadingCtrl: LoadingController, public service: UserProvider, public prom: AsyncPipeComponent, private data: DataService) {
//  console.log(this.film);
//  console.log(this.service.gett);
//this.dataSource.data = TREE_DATA;
//let response = this.service.getUsers().subscribe((result) => this.myUser = result);

  this.http = this.service;
    this.loader = this.loadingCtrl;

this.aqi = { data: {} };
    this.reload();
  
		
//     this.aqi = { data: {} };

this.bases$ = [
	{
		age: 32,
		name: 'ankle'
		
	},
	{
		age: 22,
		name: 'brace'
		
	},
	{
		age: 11,
		name: 'pox'
		
	}
	
];

      console.log('BANG BANG!');
      console.log(this.bases$);

// return this.service.subscribe(data => {
// 		this.myUser = data.favorites;
// 		this.posts = data.favorites;
		
// 				console.log(this.posts);
// console.log('done.');


// console.log(this.myUser);

// });

		
															

// // 		this.posts = data.data.children;
// // 				console.log(data.data.children);
// 		},
// 		err => {
// 			console.log('Oops!');
// 		});
//     }

      

//      //LEGACY
      
let response = this.service.gett({
	ids: 21,
    limit: 12,
    fl1: 0,
    retailerID: 28
}).subscribe(data => {
	console.log(this.data.currentMessage);
  console.log(data);
   this.listOfFoos = data; 
  
//   this.data.changeMessage(data);
	});
	
// 	//LEGACY

	//			console.log(this.courseObs);
	
// .subscribe(data => {
// // 		this.myUser = data.ip;
// 		this.posts = data;
		
// 				console.log(this.posts);
// console.log('done.');


// // console.log(this.myUser);

// });


console.log(response);
console.log(this.listOfFoos);
// return response;






}reload() {
    
		
console.log('made it in reload');
	this.title = 'Result of Reloading';
	

}


    ngOnInit():void {




 
 
			var self = this;
			
this.show = true;
	console.log(this.show);
		
      
      
// console.log(this.data.currentMessage.subscribe(message => this.message = message));
// this.data.currentMessage.subscribe(message => this.message = message)

this.data.currentMessage.subscribe(cartObject => {
	if(cartObject) this.cartObject = cartObject; 
 });
//  this.data.changeMessage({
  
// });
console.log(this.cartObject);

// 		console.log(this.title);	
			
// 				this.passes = this.prom; // .getPromise().then(v => this.promiseData = v);
//console.log(this.prom.getPromise().then(v => this.promiseData = v));
// console.log(this.passes);
// this.asses = this.prom;
// console.log(this.asses);
//console.log(this.prom);

// console.log(this.prom.getPromise());

hello();



			console.log('ngOnInit Running in home.page');
			
// 			console.log(this.aqi);
// 			console.log(this.service.gett('8.8.2.2'));
			
// 			console.log(this.curse.description);
			
//  this.bases$ = this.prom.getPromise().then(v => this.promiseData = v); this.service.gett('frozoooe');
 
 //this.bases$ = this.prom.getPromise().then(v => this.promiseData = v);
//  this.service.gett('frozoooe');
//  this.aqi = this.service.gett('feewewelle');
//  	console.log(this.show);
		
//  	.subscribe(data => { 
// 		this.aqi=data;
// 			console.log(this.aqi);	

// 	},
// 	err => {
// 		console.log('ooppsy!');
// 	}
// );



//  	.subscribe((response) => {
// 		this.aqi = response;
// 		this.posts = response;
// 		console.log(response); 	
//  		console.log(this.aqi);
// 		//console.log(this.bases$);
// 		this.title = 'hello fox';
// 		console.log(this.title);
	
// 	data => {
	
// let response = this.service.gett('8.8.8.1').subscribe(data => {

// this.myUser = data;
// this.bases$ = data;

    
		
// 		   this.courseObs = data;

	
// 	console.log('oyeeeeeeeeee!');
	

			
			
//     }







}
 newMessage() {
    this.data.changeMessage("HOME IS WHERE IT IS")
    console.log('HOME RUN!  click!');
    
  }
 

   

generateArray(obj){
    return Object.keys(obj).map((key)=>{ return {key:key, value:obj[key]}});
} 

}


