import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-sidebar-widget-2',
  templateUrl: './sidebar-widget-2.component.html',
  styleUrls: ['./sidebar-widget-2.component.css']
})
export class SidebarWidget2Component implements OnInit {
  message:string;
  cartObject:object;
  
  constructor(
    private data: DataService
  ) { }

  ngOnInit() {
    console.log('SIDEBAR-2 reporting for duty!');
    this.data.currentMessage.subscribe(cartObject => {
      if(cartObject) this.cartObject = cartObject; 
   });
   console.log(this.cartObject);
  }

}
