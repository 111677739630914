import {
    trigger,
    style,
    animate,
    transition,
    query,
    animateChild,
    group,
    stagger,
     state
  } from '@angular/animations';
  
  const ShakeAnimation = [
    style({ transform: 'rotate(0)' }),
    animate('0.1s', style({ transform: 'rotate(2deg)' })),
    animate('0.1s', style({ transform: 'rotate(-2deg)' })),
    animate('0.1s', style({ transform: 'rotate(2deg)' })),
    animate('0.1s', style({ transform: 'rotate(0)' })),
  ];
  
  // export const QueryShake = [
  //   trigger('queryShake', [
  //     transition('* => default', [query('.mavericks', ShakeAnimation,{
  //       optional: false,
  //       limit: 2,
  //     })]),
  //     transition('* => withLimit', [
  //       query('.mavericks', ShakeAnimation, {
  //         optional: true,
  //         limit: 2,
  //       }),
  //     ]),
  //   ]),
  // ];


  export const QueryShake = trigger("queryShake", [
    transition("* => *", [
      // each time the binding value changes
      query(".mavericks", ShakeAnimation, {
        optional: true
      }),
      query(
        ".mavericks",
        [
          style({ opacity: 0 }),
          stagger(500, ShakeAnimation
            )
        ],
        { optional: true }
      )
    ])
  ]);