import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// FOR ANY I ADDED SUBJECT
import { Subject } from 'rxjs';

@Injectable()
export class DataService {

  // Default Message was the original BehaviorSubject. Changed it to a retailor
  private messageSource = new BehaviorSubject<Object>({
    // ids: 21,
    // limit: 12,
    fl1: 0,
    retailerID: 8,
    // searchText: "dress yellow"
  });
  currentMessage = this.messageSource.asObservable();

  // Default Message was the original BehaviorSubject. Changed it to a retailor
  private catSource = new BehaviorSubject<Object>({
    // ids: 21,
    // limit: 12,
    // fl1: 0,
    // retailerID: 8,
    // searchText: "dress yellow"
    // cat: "mens-clothes"

    // Uncomment below if you want just womens clothing
    cat: "womens-clothes"
    // Uncomment below if you want all categories
    // cat: "clothes-shoes-and-jewelry"
  });
  currentCat = this.catSource.asObservable();  

// BRANDS
  // Default Message was the original BehaviorSubject. Changed it to a retailor
  private brandSource = new BehaviorSubject<Object>({
    // ids: 21,
    // limit: 12,
    // fl1: 0,
    // retailerID: 8,
    // searchText: "dress yellow"
    // cat: "mens-clothes"

    // Uncomment below if you want just womens clothing
    brand: "adidas"
    // Uncomment below if you want all categories
    // cat: "clothes-shoes-and-jewelry"
  });
  currentBrand = this.brandSource.asObservable();  
// BRANDS END

// RETAILERS
  // Default Message was the original BehaviorSubject. Changed it to a retailor
  private retailerSource = new BehaviorSubject<Object>({
    // ids: 21,
    // limit: 12,
    // fl1: 0,
    // retailerID: 8,
    // searchText: "dress yellow"
    // cat: "mens-clothes"

    // Uncomment below if you want just womens clothing
    retailer: "Target"
    // Uncomment below if you want all categories
    // cat: "clothes-shoes-and-jewelry"
  });
  currentRetailer = this.retailerSource.asObservable();  
// RETAILERS END


// ANY
  // Default Message was the original BehaviorSubject. Changed it to a retailor
  private anySource = new BehaviorSubject<Object>({
    // ids: 21,
    // limit: 12,
    // fl1: 0,
    // retailerID: 8,
    // searchText: "dress yellow"
    // cat: "mens-clothes"

    // Uncomment below if you want just womens clothing
    retailer: "Target"
    // Uncomment below if you want all categories
    // cat: "clothes-shoes-and-jewelry"
  });
  currentAny = this.anySource.asObservable();  
// ANY END


  constructor() { }

  // DATA (ANY) SERVICER START
  //Using any
  // public editDataDetails: any = [];
  
  // public subject = new Subject<any>();

  // private messageSourceAny = new  BehaviorSubject(this.editDataDetails);

  // currentMessageAny = this.messageSourceAny.asObservable();

  // exp

  changeAnyValue(message: any) {
    return this.anySource.getValue();
  }  

  getCurrentMessageAny(){
    return this.anySource.getValue();
    // this.messageSource.value
    // return this.anySource.value;
  
  }
  
  //end exp
  
  changeMessageAny(message: any) {
    return this.anySource.next(message);
  }

  // DATA (ANY SERVICER END

  changeMessage(cartObject: any) {
    this.messageSource.next(cartObject)
  }

  changeValue(theChangedProp: any) {
    this.messageSource.next({...this.messageSource.value, ...theChangedProp})
  }

  changeCat(cartObject: any) {
    this.catSource.next(cartObject)
  }

  changeCatValue(theChangedProp: any) {
    this.catSource.next({...this.catSource.value, ...theChangedProp})
  }  

// BRANDS
changeBrand(cartObject: any) {
  this.brandSource.next(cartObject)
}

changeBrandValue(theChangedProp: any) {
  this.brandSource.next({...this.brandSource.value, ...theChangedProp})
}  
// END BRANDS

getCurrentMessage(){
  // this.messageSource.value
  return this.messageSource.value;

}

// RETAILERS
changeRetailer(cartObject: any) {
  this.retailerSource.next(cartObject)
}

changeRetailerValue(theChangedProp: any) {
  this.retailerSource.next({...this.retailerSource.value, ...theChangedProp})
}  
// END RETAILERS



// paste above this line or die!
}