// Angular Modules
import { Injectable } from '@angular/core'; 
@Injectable() 
export class Constants {
public readonly API_ENDPOINT_APIGW_STAGE = "/prod/";
public readonly API_ENDPOINT_HTTPS = "https://";
public readonly API_ENDPOINT: string = "https://api.alleysix.com";
// public readonly API_ENDPOINT_APIGW = "https://5sm2yd4nni.execute-api.us-east-1.amazonaws.com/prod";
public readonly API_ENDPOINT_PATH_INIT = "/api/v2";
public readonly API_ENDPOINT_PATH_LIST = "/lists";
public readonly API_ENDPOINT_PATH_SEARCH = "/search/";
public readonly API_ENDPOINT_PATH_CATEGORY = "/categories";
public readonly API_ENDPOINT_PATH_BRAND = "/brands";
public readonly API_ENDPOINT_PATH_RETAILER = "/retailers";
public readonly API_KEY: string = "?pid=uid5321-992139-18"; 
// public readonly API_MOCK_ENDPOINT: string = 'https://www.userdomainmock.com/'; 
public static TitleOfSite: string = " Making API calls with Constants!";

// https://api.shopstyle.com/api/v2/brands?pid=uid5321-992139-18
// http://api.shopstylecollective.com/api/v2/brands?pid=uid5321-992139-18
// http://api.shopstylecollective.com/api/v2/brands?pid=YOUR_API_KEY
// const url2 = '/search?pid=';
// const url2 = 'http://api.shopstyle.com/api/v2/lists/search?pid=uid5321-992139-18&fl=d0&fts=wire&fl=b3399&limit=50';
// const allCats = 'http://api.shopstyle.com/api/v2/categories?pid=uid5321-992139-18';
} 