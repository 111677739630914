import { Component, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-sidebar-widget-1',
  templateUrl: './sidebar-widget-1.component.html',
  styleUrls: ['./sidebar-widget-1.component.css']
})
export class SidebarWidget1Component implements OnInit {
message:string;
cartObject:object;

  constructor(
    private data: DataService
  ) { }

  ngOnInit() {
    console.log('SIDEBAR-1 reporting for duty!');
    // this.data.currentMessage.subscribe(message => this.message = message);
    // console.log(this.message);
    this.data.currentMessage.subscribe(cartObject => {
      if(cartObject) this.cartObject = cartObject; 
   });
  //  this.data.changeMessage({
    
  // });
console.log(this.cartObject);

  }

  newMessage(){
    this.data.changeValue(
      {
        limit: 4,
        retailerID: 22,
        // searchText: "",
    }
    );
  }
  // newMessage(){
  //   this.data.changeMessage({
  //     ids: 15,
  //     limit: 4,
  //     fl1: 0,
  //     retailerID: 33,
  //     searchText: "blue dress",
  //   });
  //   console.log('hey new message');
  // }

}